//this is a manual implementation
//RE: can't use useGetAsset inside another hook
import { useEffect, useState } from 'react';
import { Asset, getAsset } from 'v2/lib/api/ad-management';
export const useGetAssets = (assetIds: Array<Asset['id']>) => {
  const [assets, setAssets] = useState<Array<Asset>>([]);
  useEffect(() => {
    if (assetIds && assetIds?.length > 0) {
      Promise.all(assetIds.map((id) => getAsset(id)))
        .then((results) => {
          setAssets(results.map((result) => result?.data));
        })
        .catch(() => {
          return; //<- return early
        });
    }
  }, [assetIds, getAsset]);

  return assets;
};

import { Box, Typography, useTheme } from '@the-pistol/zitcha-component-library';
import React, { useEffect, useRef, useState } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import type { Ad, Asset } from 'v2/lib/api/ad-management';
import LinearProgress from '@mui/material/LinearProgress';
import { FilePreviews } from './FilePreviews';
import { useGetAssets } from './useGetAssets';

interface DropFileInputProps {
  onFileChange: (fileList: Array<File>) => void;
  setAssets: (asset: Array<Asset>) => void;
  files: Array<Asset>;
  uploading: boolean;
  ad: Ad;
}

export const DragAndDropUploader = ({ onFileChange, files, setAssets, uploading, ad }: DropFileInputProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const theme = useTheme();
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState<Array<File>>([]);

  const onDragEnter = () => {
    setIsDragging(true);
  };

  const onDragLeave = () => {
    setIsDragging(false);
  };

  const onDrop = () => {
    setIsDragging(false);
  };

  const onFileDrop = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) return;
    const maxSize = 25 * 1024 * 1024; // 25MB in bytes
    const newFile = event.target.files[0];
    if (newFile) {
      if (newFile.size <= maxSize) {
        if (newFile.type.startsWith('image/') || newFile.type.startsWith('video/')) {
          const updatedList = [...fileList, newFile];
          setFileList(updatedList);
          onFileChange(updatedList);
        } else {
          alert('Only images and videos are allowed');
        }
      } else {
        alert('File size exceeds 25MB');
      }
    }
  };
  const prevAssets = useGetAssets(ad?.fields?.[0]?.assets);
  useEffect(() => {
    if (prevAssets.length > 0) {
      setAssets(prevAssets);
    }
  }, [prevAssets]);

  const fileRemove = (file: Asset) => {
    const updatedList = files.filter((item) => item.id !== file.id);
    setAssets(updatedList);
  };

  return (
    <>
      <div
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        style={{
          border: '2px dashed',
          borderColor: ` ${isDragging ? theme.palette.primary.main : '#ccc'}`,
          borderRadius: '5px',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        <Box display='flex' justifyContent='center' flexDirection='column' gap={2} alignItems='center'>
          <UploadFileIcon sx={{ color: theme.palette.primary.main }} />
          <Box>
            <Box sx={{ display: 'flex', gap: '.5em' }}>
              <Typography variant='inherit' sx={{ color: theme.palette.primary.main, textDecoration: 'underline' }}>
                Click to upload{' '}
              </Typography>{' '}
              or drag and drop
            </Box>
            <Typography
              variant='inherit'
              sx={{
                color: theme.palette.text.secondary,
                marginTop: '.5em',
                fontSize: '14px',
              }}
            >
              PSD, JPG or PNG (max. 25MB)
            </Typography>
          </Box>
        </Box>
        <input
          data-testid='fileInput'
          type='file'
          value=''
          onChange={onFileDrop}
          accept='image/*,video/*'
          style={{
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: ' 100%',
            height: '100%',
            cursor: 'pointer',
          }}
        />
      </div>
      <Box
        sx={{
          height: '2px',
          width: '100%',
          marginTop: '1rem',
        }}
      >
        {uploading ? <LinearProgress sx={{ color: theme.palette.primary.main }} /> : null}
      </Box>
      {files.length > 0 ? <FilePreviews files={files} deleteFile={fileRemove} /> : null}
    </>
  );
};

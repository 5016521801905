import React, { useContext } from 'react';
import { Box, Button, FactCheckIcon, Typography } from '@the-pistol/zitcha-component-library';
import { NavigationContext } from 'v2/components/Navigation/Chrome';
import { useNavigate } from 'react-router-dom';
import { NewPlanModalButton } from 'v2/components/NewPlanModal/NewPlanModalButton';

export const DashboardHeadingSection = () => {
  const { user } = useContext(NavigationContext);
  const navigate = useNavigate();
  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' mt={3}>
      <Box id='dashboard-heading-section'>
        <Typography variant='h4' data-testid='pageHeading'>
          Welcome back, {user?.first_name}.
        </Typography>
      </Box>

      <Box gap={3} display='flex'>
        <Button
          variant='text'
          color='primary'
          onClick={() => {
            navigate('/plans-ad-sets?tab=plans');
          }}
          startIcon={<FactCheckIcon />}
          id='view-plans-button'
          aria-label='View plans'
        >
          View Plans
        </Button>
        <NewPlanModalButton />
      </Box>
    </Box>
  );
};

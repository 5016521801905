import { useQueryClient } from '@tanstack/react-query';
import { useTransitionAdSet, useUpdateAdSet } from 'v2/lib/api/ad-management';
import { AdSet, AdSetStatusEnum, UpdateAdSetBody } from 'v2/lib/api/ad-management/model';

// Add optional page and perPage parameters to the useAdSetActions hook.
// Pass these parameters if you want to optimistically update usePlanAdSets paginated queries.
// todo refactor as prop of updateAdSetStatus and updateAdSetStatuses explicitly/directly
export const useAdSetActions = (usePlanAdSetsPage?: number, usePlanAdSetsPerPage?: number) => {
  const transitionAdSet = useTransitionAdSet();
  const updateAdSetMutation = useUpdateAdSet();
  const queryClient = useQueryClient();

  const updateAdSetStatus = async (adSetId: string, status: AdSetStatusEnum) => {
    const previousAdSets = queryClient.getQueryData(['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage]);

    queryClient.setQueryData(
      ['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage],
      (old: { data: Array<AdSet> }) => {
        if (!old || !Array.isArray(old.data)) {
          return old;
        }

        return {
          ...old,
          data: old.data.map((adSet: AdSet) => {
            if (adSet.id === adSetId) {
              return { ...adSet, status };
            }
            return adSet;
          }),
        };
      }
    );

    try {
      await transitionAdSet.mutateAsync(
        { id: adSetId, data: { status } },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage],
            });
          },
        }
      );
    } catch (error) {
      queryClient.setQueryData(['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage], previousAdSets);
      throw error;
    }
  };

  const approveAdSet = (adSetId: string) => updateAdSetStatus(adSetId, AdSetStatusEnum.reserved);
  const rejectAdSet = (adSetId: string) => updateAdSetStatus(adSetId, AdSetStatusEnum.rejected);

  const updateAdSetStatuses = async (adSetIds: Array<string>, status: AdSetStatusEnum) => {
    const previousAdSets = queryClient.getQueryData(['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage]);

    queryClient.setQueryData(
      ['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage],
      (old: { data: Array<AdSet> }) => {
        if (!old || !Array.isArray(old.data)) {
          return old;
        }

        return {
          ...old,
          data: old.data.map((adSet: AdSet) => {
            if (adSetIds.includes(adSet.id as string)) {
              return { ...adSet, status };
            }
            return adSet;
          }),
        };
      }
    );

    try {
      const updatePromises = adSetIds.map((adSetId) => transitionAdSet.mutateAsync({ id: adSetId, data: { status } }));
      await Promise.all(updatePromises);
      queryClient.invalidateQueries({
        queryKey: ['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage],
      });
    } catch (error) {
      queryClient.setQueryData(['usePlanAdSets', usePlanAdSetsPage, usePlanAdSetsPerPage], previousAdSets);
      throw error;
    }
  };

  const approveBulkAdSets = (adSetIds: Array<string>) => updateAdSetStatuses(adSetIds, AdSetStatusEnum.reserved);
  const rejectBulkAdSets = (adSetIds: Array<string>) => updateAdSetStatuses(adSetIds, AdSetStatusEnum.rejected);

  const updateAdSet = async ({ id, data }: { id: string; data: UpdateAdSetBody }) => {
    try {
      await updateAdSetMutation.mutateAsync({ id, data });
      queryClient.invalidateQueries({
        predicate: (query) => {
          const queryKey = query.queryKey as Array<string>;
          const firstKey = Array.isArray(queryKey) ? queryKey[0] : '';
          return firstKey === 'usePlanAdSets' || firstKey === 'searchAdSets';
        },
      });
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error('TODO: error handling: updateAdSet error', error);
    }
  };

  return {
    approveAdSet,
    rejectAdSet,
    approveBulkAdSets,
    rejectBulkAdSets,
    updateAdSet,
    processing: transitionAdSet.isLoading,
  };
};

import React, { useMemo } from 'react';
import { Alert, Box, GridColDef, GridPaginationModel, DataGrid } from '@the-pistol/zitcha-component-library';
import { useFetchPlans } from 'v2/lib/hooks/useFetchPlans';
import { PerPageEnum, PlanSearch, Plan } from 'v2/lib/api/ad-management';
import { PlansTableFilters } from './PlansTableFilters';
import { debounce } from 'debounce';
import {
  renderAdSetActions,
  renderActionButtons,
  renderStatusChip,
  renderLastModified,
  renderWalletDateRange,
} from './TableCellRenderers';
import { useNavigate } from 'react-router-dom';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

export const PlansTable: React.FC = () => {
  const navigate = useNavigate();

  const organisation = useUserOrganisation();
  const { plansData, planPagination, changePlanPagination, isLoadingPlans, plansError, setPlanSearch } = useFetchPlans({
    owner_organisation_ids: [organisation.id],
    statuses: undefined,
    supplier_organisation_ids: undefined,
    wallet_ids: undefined,
    wallet_start_date: undefined,
    wallet_end_date: undefined,
  });

  const rows = plansData?.map((plan: Plan) => ({
    id: plan.id,
    name: plan.name,
    walletDateRange: `${plan.wallet?.startAt} - ${plan.wallet?.endAt}`,
    brand: plan.supplier?.name,
    planStatus: plan.status,
    lastModified: plan.updatedAt,
    adSets: plan.adSetCount,
    adSetActions: plan,
    planValue: plan?.value,
    budgetLeft: plan?.budgetLeft,
    actions: plan.id,
  }));

  const dataGridPaginationModel = {
    pageSize: planPagination.perPage ?? PerPageEnum.NUMBER_15,
    page: (planPagination.currentPage ?? 1) - 1, //-1 to match 0 vs 1 based indexing
  };

  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changePlanPagination(model.page + 1, model.pageSize as PerPageEnum);
  };

  const debouncedSetPlanSearch = useMemo(() => debounce(setPlanSearch, 300), [setPlanSearch]);
  const handleFilterChange = (newFilter: PlanSearch) => {
    debouncedSetPlanSearch((prevFilter: PlanSearch) => ({ ...prevFilter, ...newFilter }));
  };

  const columns: Array<GridColDef> = [
    { field: 'name', headerName: 'Plan name', width: 150 },
    { field: 'walletDateRange', headerName: 'Wallet date range', width: 180, renderCell: renderWalletDateRange },
    { field: 'brand', headerName: 'Brand', width: 150 },
    { field: 'planStatus', headerName: 'Plan status', renderCell: renderStatusChip, width: 110 },
    { field: 'lastModified', headerName: 'Last modified', width: 150, renderCell: renderLastModified },
    { field: 'adSets', headerName: 'Ad sets', width: 100 },
    { field: 'adSetActions', headerName: 'Ad set actions', width: 300, renderCell: renderAdSetActions },
    { field: 'planValue', headerName: 'Plan value', width: 100 },
    { field: 'budgetLeft', headerName: 'Budget left', width: 100 },
    { field: 'actions', headerName: 'Actions', width: 100, renderCell: (params) => renderActionButtons(params, navigate) },
  ];

  return (
    <Box sx={{ backgroundColor: 'white' }} padding={2} mt={3}>
      <PlansTableFilters onFilterChange={handleFilterChange} />
      {plansError && <Alert severity='error'>An error occurred while fetching ad sets.</Alert>}
      <DataGrid
        disableColumnFilter
        disableColumnSorting
        rows={rows ?? []}
        paginationMode='server'
        paginationModel={dataGridPaginationModel}
        rowCount={planPagination?.total || 0}
        onPaginationModelChange={handlePaginationUpdate}
        columns={columns}
        pageSizeOptions={[5, 10, 15, 25, 50, 100]}
        autoHeight
        loading={isLoadingPlans}
      />
    </Box>
  );
};

import {
  Box,
  Drawer,
  List,
  Toolbar,
  WarehouseIcon,
  StoreIcon,
  ShoppingCartCheckoutIcon,
  SettingsIcon,
  ReceiptIcon,
  FactCheckIcon,
  PersonAddIcon,
  InventoryIcon,
  HubIcon,
  DashboardIcon,
  AddBusinessIcon,
  AccountTreeIcon,
  AccountBalanceWalletIcon,
} from '@the-pistol/zitcha-component-library';
import React, { useContext, useState } from 'react';
import { AddPlanBtn } from './AddPlanBtn';
import { NavigationContext } from './Chrome';
import { Collapsible } from './Collapsible';
import { MenuItem } from './MenuItem';

const SideNav = () => {
  const { drawerWidth, drawerSizes, permissions, toggleDrawerWidth } = useContext(NavigationContext);
  const drawerIsCollapsed = drawerWidth === drawerSizes.collapsed;
  const ICON_ONLY = drawerIsCollapsed;
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [plansAdSetsOpen, setPlansAdSetsOpen] = useState(false);

  return (
    <Box>
      <Drawer
        anchor='left'
        variant={ICON_ONLY ? 'permanent' : 'temporary'}
        open={drawerWidth === drawerSizes.expanded}
        onClose={toggleDrawerWidth}
        sx={{
          width: `${drawerWidth}rem`,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: `${drawerWidth}rem`, boxSizing: 'border-box', transition: 'width 0.3s' },
        }}
      >
        <Toolbar />
        <Box
          component='div'
          display='flex'
          sx={{
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            padding: '.5rem',
          }}
        >
          <Box component='aside'>
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '.5rem',
              }}
            >
              {permissions.canCreateOrders && <AddPlanBtn onlyIcon={ICON_ONLY} />}
              <MenuItem Icon={DashboardIcon} link='/' title='Dashboard' onlyIcon={ICON_ONLY} />
              {permissions.hasBrandStoresEnabled && (
                <MenuItem Icon={StoreIcon} link='/brandstores/home' title='Brand stores' onlyIcon={ICON_ONLY} />
              )}
              {permissions.hasWallets && (
                <MenuItem Icon={AccountBalanceWalletIcon} link='/wallets' title='Wallets' onlyIcon={ICON_ONLY} />
              )}
              <MenuItem Icon={ReceiptIcon} link='/orders' title='Orders' onlyIcon={ICON_ONLY} />
              <Collapsible
                Icon={FactCheckIcon}
                title='Plans & ad sets'
                onlyIcon={ICON_ONLY}
                openState={plansAdSetsOpen}
                setOpenState={setPlansAdSetsOpen}
                childLinks={['/plans-ad-sets']}
              >
                <MenuItem link='/plans-ad-sets?tab=plans' title='Plans' />
                <MenuItem link='/plans-ad-sets?tab=ad-sets' title='Ad sets' />
              </Collapsible>
              {permissions.isRetailer && permissions.hasInventoryManagementEnabled && (
                <MenuItem Icon={WarehouseIcon} link='/inventory/home' title='Inventory' onlyIcon={ICON_ONLY} />
              )}
            </List>
          </Box>
          {permissions.hasPermissions && (
            <Box component='aside'>
              <List
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '.5rem',
                }}
              >
                {!permissions.isRetailer && (
                  <MenuItem Icon={SettingsIcon} link='/organisation/settings' title='Settings' onlyIcon={ICON_ONLY} />
                )}

                {permissions.isRetailer && (
                  <Collapsible
                    Icon={SettingsIcon}
                    title='Settings'
                    onlyIcon={ICON_ONLY}
                    openState={settingsOpen}
                    setOpenState={setSettingsOpen}
                    childLinks={[
                      '/organisation/settings',
                      '/organisation/channels',
                      '/settings/suppliers',
                      '/packages',
                    ]}
                  >
                    {permissions.canManageUsers && (
                      <MenuItem Icon={AccountTreeIcon} link='/organisation/settings' title='Organisation' />
                    )}
                    {permissions.canManageChannelWeb && permissions.channelsEnabledFlagSet && (
                      <MenuItem Icon={HubIcon} link='/organisation/channels' title='Channels' />
                    )}
                    {permissions.isRetailer && permissions.canManageSuppliers && (
                      <MenuItem Icon={ShoppingCartCheckoutIcon} link='/settings/suppliers' title='Suppliers' />
                    )}
                    {permissions.isRetailer && <MenuItem Icon={InventoryIcon} link='/packages' title='Packages' />}
                  </Collapsible>
                )}
                {permissions.canInviteEmployees && (
                  <MenuItem Icon={PersonAddIcon} link='/users/invite' title='Invite user' onlyIcon={ICON_ONLY} />
                )}
                {permissions.canInviteSupppliers && (
                  <MenuItem
                    Icon={AddBusinessIcon}
                    link='/organisations/invite'
                    title='Invite supplier'
                    onlyIcon={ICON_ONLY}
                  />
                )}
              </List>
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export { SideNav };

import React from 'react';
import { ViewPlanAdSetsTable } from '../reviewAdSets/ViewPlanAdSetsTable';
import { ViewMode } from '../ViewModeType';
import { Box } from '@mui/material';
import { NewPlanAdSets } from '../../NewPlanAdSets';
import { AdSet } from 'v2/lib/api/ad-management';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

export const EditPlanTables = ({
  planId,
  newAdSets,
  addNewAdSet,
  removeNewAdSet,
  updateNewAdSet,
  setNewAdSets,
}: {
  planId: string;
  newAdSets: Array<AdSet>;
  addNewAdSet: (adSet: AdSet) => void;
  removeNewAdSet: (id: string) => void;
  updateNewAdSet: (adSet: AdSet) => void;
  setNewAdSets: (adSets: Array<AdSet>) => void;
}) => {
  const organisation = useUserOrganisation();

  return (
    <>
      <Box mb={4}>
        <ViewPlanAdSetsTable planId={planId} viewMode={ViewMode.EDITING} />
      </Box>
      <Box mb={1}>
        <NewPlanAdSets
          organisationId={organisation.id}
          adSets={newAdSets}
          addAdSet={addNewAdSet}
          removeAdSet={removeNewAdSet}
          updateAdSet={updateNewAdSet}
          setAdSets={setNewAdSets}
          viewMode={ViewMode.EDITING}
        />
      </Box>
    </>
  );
};

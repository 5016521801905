import React from 'react';
import { Box, Button, CheckIcon, ClearIcon, Snackbar } from '@the-pistol/zitcha-component-library';
import { AdSet } from 'v2/lib/api/ad-management';

type ApproveRejectAllAdsetsSnackbarProps = {
  open: boolean;
  selectedAdSets: Array<AdSet>;
  handleApproveAll: () => void;
  handleRejectAll: () => void;
};

export const ApproveRejectAllAdsetsSnackbar: React.FC<ApproveRejectAllAdsetsSnackbarProps> = ({
  open,
  selectedAdSets,
  handleApproveAll,
  handleRejectAll,
}) => {
  return (
    <Snackbar
      open={open}
      message={`${selectedAdSets.length} ad sets selected`}
      action={
        <Box display='flex' alignItems='center' justifyContent='space-between' gap={1}>
          <Button color='success' startIcon={<CheckIcon />} onClick={handleApproveAll}>
            Approve
          </Button>
          <Button color='error' startIcon={<ClearIcon />} onClick={handleRejectAll}>
            Reject
          </Button>
        </Box>
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      ContentProps={{
        sx: {
          background: 'white',
          color: 'black',
          padding: 2,
        },
      }}
    />
  );
};

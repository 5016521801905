import React, { ReactNode, useEffect } from 'react';
import {
  Box,
  Stack,
  TextField,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InfoIcon,
  Autocomplete,
} from '@the-pistol/zitcha-component-library';
import { AdSet, useGetBundle, useGetBundles, useGetCalendarPeriods } from 'v2/lib/api/ad-management';
import Placement from './Placement';
import SkuCodes from './SkuCodes';
import { formatDisplayDate } from 'helpers/DateHelpers';
import { useSelector } from 'react-redux';
import { selectAdTypes } from 'features/session/sessionSlice';
import type { AdType, CalendarPeriod, Location } from 'v2/lib/api/ad-management';
import { RenderCurrency } from '../RenderCurrency/RenderCurrency';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

type DetailsTableProps = {
  data: AdSet;
  selectedSchedule: CalendarPeriod | null;
  setSelectedSchedule: (value: CalendarPeriod | null) => void;
  selectedMediaType: AdType | null;
  setSelectedMediaType: (value: AdType | null) => void;
  selectedPlacement: Location | null;
  setSelectedPlacement: (value: Location | null) => void;
  setSelectedBundleId: (value: string | null) => void;
  monetaryValues: {
    rate: number;
    discount: number;
    budget: number;
  };
  setMonetaryValues: React.Dispatch<React.SetStateAction<{
    rate: number;
    discount: number;
    budget: number;
  }>>;
};

export const DetailsTable: React.FC<DetailsTableProps> = ({
  data,
  selectedMediaType,
  selectedPlacement,
  selectedSchedule,
  setSelectedMediaType,
  setSelectedPlacement,
  setSelectedSchedule,
  setSelectedBundleId,
  monetaryValues,
  setMonetaryValues,
}) => {
  const organisation = useUserOrganisation();
  const bundleId = data?.bundleIds?.[0] || '';
  const { data: existing_bundle, isPending, isSuccess: bundleFound } = useGetBundle(bundleId);

  const handlePlacementChange = (location: Location) => {
    setSelectedPlacement(location);
  };

  const skuCodesList = data?.ads?.map((ad) => ad?.fields?.sku) ?? [];
  const bundleKey = 'bundles';
  const {
    data: bundle,
    isSuccess,
    isLoading: loadingBundle,
  } = useGetBundles(
    {
      organisation_id: organisation?.id,
      ad_type_id: selectedMediaType?.id,
      calendar_period_id: selectedSchedule?.id,
      location_id: selectedPlacement?.id,
    },
    {
      query: {
        queryKey: [bundleKey, organisation.id, selectedMediaType?.id, selectedSchedule?.id, selectedPlacement?.id],
        enabled: !!(selectedMediaType?.id && selectedSchedule?.id && selectedPlacement?.id),
      },
    }
  );

  const adTypes = useSelector(selectAdTypes);
  const { data: schedules, isLoading } = useGetCalendarPeriods({ organisation_id: organisation.id });

  useEffect(() => {
    if (isSuccess) {
      setMonetaryValues({
        rate: bundle?.data[0]?.price ?? 0,
        discount: 0, //<- not implemented yet
        budget: bundle?.data[0]?.price ?? 0, //<- not implemented yet in the future this will be the rate minus the dicount
      });
      setSelectedBundleId(bundle?.data[0]?.id ?? null);
    }
  }, [isSuccess, bundle, loadingBundle]);

  const findPreselectedMediaType = () => {
    return adTypes?.find((adType) => adType?.id === existing_bundle?.data?.adTypeId) ?? null;
  };

  useEffect(() => {
    if (!isPending && !selectedSchedule) {
      setSelectedSchedule(existing_bundle?.data?.calendarPeriod);
    }
    if (!isPending && !selectedPlacement) {
      setSelectedPlacement(existing_bundle?.data?.locations[0]);
    }
    if (!isPending && !selectedMediaType) {
      setSelectedMediaType(findPreselectedMediaType());
    }
  }, [existing_bundle, isPending, selectedSchedule, bundleFound, selectedPlacement, selectedMediaType]);

  // todo - break this down into smaller, named components
  return (
    <Stack direction='column' spacing={2}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label='table'>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: '25%',
                  }}
                >
                  Schedule
                </TableCell>
                <TableCell
                  sx={{
                    width: '25%',
                  }}
                >
                  Media Type
                </TableCell>
                <TableCell
                  sx={{
                    width: '25%',
                  }}
                >
                  Placement
                </TableCell>
                <TableCell
                  sx={{
                    width: '25%',
                  }}
                >
                  SKU code(s)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Row>
                <TableCell>
                  <Autocomplete
                    size='medium'
                    fullWidth
                    getOptionLabel={(data) =>
                      `${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`
                    }
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderInput={(params: any) => <TextField {...params} variant='standard' />}
                    value={selectedSchedule ?? null}
                    options={schedules?.data ?? []}
                    loading={isLoading}
                    onChange={(_, newValue) => {
                      setSelectedSchedule(newValue);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Autocomplete
                    fullWidth
                    getOptionLabel={(data) => data?.label}
                    renderInput={(params: any) => <TextField {...params} variant='standard' />}
                    value={selectedMediaType ?? null}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    options={adTypes ?? []}
                    onChange={(_, newValue) => {
                      setSelectedMediaType(newValue);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Placement onPlacementChange={handlePlacementChange} location={selectedPlacement} />
                </TableCell>
                <TableCell>
                  <SkuCodes data={skuCodesList} onSkuCodesChange={(value) => {
                    //eslint-disable-next-line no-console
                    console.log('Not Implemented: sku codes', value);
                  }} />
                </TableCell>
              </Row>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label='table'>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: '33.3%',
                  }}
                >
                  Rate{' '}
                  <Tooltip title='Ad set price' placement='right-end'>
                    <InfoIcon color='primary' />
                  </Tooltip>
                </TableCell>
                <TableCell
                  sx={{
                    width: '33.3%',
                  }}
                >
                  Discount
                </TableCell>
                <TableCell
                  sx={{
                    width: '33.3%',
                  }}
                >
                  Budget{' '}
                  <Tooltip title='ad set price  minus the discount' placement='right-end'>
                    <InfoIcon color='primary' />
                  </Tooltip>
                </TableCell>
                {/* todo find out what data is, name it, use the field to create a cell key */}
                {data?.fields?.map((field: any) => <TableCell key={field.name}>{field?.label}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              <Row>
                <TableCell>
                  <RenderCurrency amount={monetaryValues.rate} />
                </TableCell>
                <TableCell>
                  <TextField
                    className='twd-w-full'
                    id='discount'
                    label=''
                    variant='standard'
                    value={monetaryValues.discount}
                    disabled={true}
                  />
                </TableCell>
                <TableCell>
                  <RenderCurrency amount={monetaryValues.budget} />
                </TableCell>
                {/* this part is not implement in the backend yet */}
                {/* {data?.fields?.map((field: any) => (
                  <TableCell key={field.name}>
                    <TextField
                      className='twd-w-full'
                      id={'id_' + field.name}
                      label=''
                      variant='standard'
                      value={field?.value ?? ''}
                      onChange={(event) => console.log(field, 'value', event.target.value)}
                    />
                  </TableCell>
                ))} */}
              </Row>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
};

export default DetailsTable;
const Row = ({ children }: { children: ReactNode }) => {
  return (
    <TableRow
      sx={{
        border: 'none',
        borderTop: 'solid rgba(0, 0, 0, 0.12)',
        borderBottom: 'solid rgba(0, 0, 0, 0.12)',
        borderWidth: 'thin',
        backgroundColor: '#FBFBFB',
      }}
    >
      {children}
    </TableRow>
  );
};

import React from 'react';
import {
    IconButton,
    Chip,
    GridRenderCellParams,
    VisibilityIcon,
    CheckIcon,
    ClearIcon,
    ErrorIcon,
    EditIcon
} from '@the-pistol/zitcha-component-library';
import { AdSet, AdSetStatusEnum, useGetBundle } from 'v2/lib/api/ad-management';
import { adSetStatusChipColorMap, adSetStatusChipVariantMap, adSetStatusNameMap } from 'v2/utils/adSetStatusUtils';
import { formatDisplayDate } from 'helpers/DateHelpers';
import { ViewMode } from '../ViewModeType';

//Plan Reivew Page renderers
export const renderActions = ({
    adSet,
    viewAdSet,
    editAdSet,
    approveAdSet,
    rejectAdSet,
    viewMode
}: {
    adSet: AdSet,
    viewAdSet: (adSet: AdSet) => void,
    editAdSet: (adSet: AdSet) => void,
    approveAdSet: (id: string) => void,
    rejectAdSet: (id: string) => void,
    viewMode: ViewMode,
}) => {
    if (!adSet?.id) {
        return null;
    }

    const adSetIsApprovedOrRejected = adSet.status === AdSetStatusEnum.reserved || adSet.status === AdSetStatusEnum.rejected;
    const adSetHasClash = adSet.status === AdSetStatusEnum.clashed;

    if (viewMode === ViewMode.EDITING) {
        return (
            <IconButton
                color='default'
                ariaLabel='Edit plan button'
                onClick={() => {
                    editAdSet(adSet);
                }}
            >
                <EditIcon />
            </IconButton>
        )
    }
    else if (adSetHasClash) {
        return (
            <IconButton
                color='error'
                ariaLabel='Error icon'
                onClick={() => {
                    editAdSet(adSet);
                    //TODO: navigate to clash management
                }}
            >
                <ErrorIcon />
            </IconButton >
        );
    } else if (adSetIsApprovedOrRejected) {
        return (
            <IconButton
                color='default'
                ariaLabel='View plan button'
                onClick={() => {
                    viewAdSet(adSet);
                }}
            >
                <VisibilityIcon />
            </IconButton>
        );
    } else {
        return (
            <>
                <IconButton
                    color='default'
                    ariaLabel='View plan button'
                    onClick={() => {
                        viewAdSet(adSet);
                    }}
                >
                    <VisibilityIcon />
                </IconButton>
                <IconButton
                    color='success'
                    ariaLabel='Approve ad set'
                    onClick={() => {
                        if (adSet.id) {
                            approveAdSet(adSet.id);
                        }
                    }}
                >
                    <CheckIcon />
                </IconButton>
                <IconButton
                    color='error'
                    ariaLabel='Reject ad set'
                    onClick={() => {
                        if (adSet.id) {
                            rejectAdSet(adSet.id);
                        }
                    }}
                >
                    <ClearIcon />
                </IconButton>
            </>
        );
    }
};

export const renderAdSetActions = (params: GridRenderCellParams) => {
    const adSetStatus = params.value as AdSetStatusEnum;
    const statusColor = adSetStatusChipColorMap[adSetStatus] || 'default';
    const statusVariant = adSetStatusChipVariantMap[adSetStatus] || 'filled';
    const statusLabel = adSetStatusNameMap[adSetStatus] || adSetStatus;
    return (
        <Chip label={statusLabel} color={statusColor} variant={statusVariant} />
    );
};

export const RenderSchedule: React.FC<{ bundleIds: Array<string> }> = ({ bundleIds }) => {
    const firstBundleId = bundleIds?.[0];
    const { data: bundleData, isLoading: isBundleLoading, error: bundleError } = useGetBundle(firstBundleId);

    if (isBundleLoading || bundleError || !bundleData || !firstBundleId) {
        return null;
    }

    const calendarPeriod = bundleData.data.calendarPeriod;
    const calendarPeriodName = calendarPeriod?.name;
    const calendarPeriodStartAt = calendarPeriod?.startAt;
    const calendarPeriodEndAt = calendarPeriod?.endAt;

    return (
        <div>
            {`${calendarPeriodName} (${formatDisplayDate(calendarPeriodStartAt)} - ${formatDisplayDate(calendarPeriodEndAt)})`}
        </div>
    );
}
import { Box, Typography } from '@the-pistol/zitcha-component-library';
import React from 'react';

interface PageSectionProps {
  id: string;
  children?: React.ReactNode;
  heading?: string;
  subHeading?: string;
  hideSection?: boolean;
}

export const PageSection = ({ id, children, heading, subHeading, hideSection = false }: PageSectionProps) => {
  if (hideSection) {
    return null;
  }

  return (
    <Box id={id} data-testid={id} mt={3}>
      {heading && (
        <Box id={`${id}-heading-section`} mt={2}>
          <Typography variant='h5' data-testid='pageHeading'>
            {heading}
          </Typography>
        </Box>
      )}
      {subHeading && (
        <Box id={`${id}-subheading-section`} mt={2}>
          <Typography variant='body1' data-testid='pageHeading'>
            {subHeading}
          </Typography>
        </Box>
      )}
      <Box id={`${id}-content-section`} mt={2}>
        {children}
      </Box>
    </Box>
  );
};

import React, { useEffect, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, ProgressBar, Row, Spinner, Tooltip } from 'react-bootstrap';
import { styled } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { ColorPicker } from './ColorPicker';
import { uploadOrganisationNetworkLogo } from '../../lib/api';
import { toast } from 'react-toastify';
import useNetworkBranding, { useNetworkBrandingMutation } from './useNetworkBranding';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/session/sessionSlice';

const percLoaded = (p: { loaded: number; total: number }) => (p.loaded / p.total) * 100;

type NetworkLogo = { creator_id: string; extension: string; name: string; path: string; storage: string; url: string };
type NetworkLogoRes = {
  network_logo: NetworkLogo;
};

function NetworkBranding() {
  const user = useSelector(selectUser);
  const { data } = useNetworkBranding();
  const settings = data?.settings;
  const mutation = useNetworkBrandingMutation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [color, setColor] = React.useState<string>(settings?.theme.primary_colour);
  const [networkLogo, setNetworkLogo] = useState<NetworkLogoRes>({
    network_logo: settings?.network_logo,
  });

  const handleColorChange = (color: string) => {
    setColor(color);
  };

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate({
      network_logo: networkLogo?.network_logo,
      theme: { primary_colour: color },
    });
  };
  useEffect(() => {
    setNetworkLogo({
      network_logo: settings?.network_logo,
    });
    setColor(settings?.theme.primary_colour);
  }, [data]);
  const errors = mutation?.error?.response?.data?.errors;
  const HAS_NETWORK_LOGO =
    networkLogo?.network_logo?.url && networkLogo?.network_logo?.url !== '/Zitcha_Brandmark White.png';
  const MAX_FiLE_SIZE = 5242880;
  return (
    <div>
      <Row>
        <Col>
          <div className='rounded py-3 d-flex'>
            <StyledTitle className='d-inline-block'>
              <h3 className='font-weight-bold'>Network branding</h3>
              <hr />
            </StyledTitle>
          </div>
        </Col>
      </Row>
      <Paragraph>Suppliers will see this branding on the navigation bar when they use the platform.</Paragraph>

      <Form onSubmit={handleSave}>
        <Form.Group>
          <Label style={{ marginBottom: '1rem' }}>Network logo</Label>
          <FileContainer>
            <Form.File
              type='file'
              id='network_logo'
              label={networkLogo?.network_logo?.name ? 'Change network logo' : 'Choose file'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files?.[0]?.size > MAX_FiLE_SIZE) {
                  alert('The file must be under 5MB.');
                  return;
                }
                uploadOrganisationNetworkLogo(e.target.files?.[0], {
                  onUploadProgress: (p: { loaded: number; total: number }) => setUploadProgress(percLoaded(p)),
                })
                  .then((resp) => {
                    setNetworkLogo((prev) => ({
                      ...prev,
                      network_logo: resp.data.network_logo,
                    }));
                    setUploadProgress(0);
                  })
                  .catch(() => {
                    toast.error('Error uploading network logo', {
                      bodyClassName: 'network_branding_toast',
                    });
                    setUploadProgress(0);
                  });
              }}
              custom
            />

            <StyledImage
              style={{
                backgroundColor: HAS_NETWORK_LOGO ? color : 'inherit',
                border: HAS_NETWORK_LOGO ? 'none' : '2px dashed #ddd',
              }}
            >
              {HAS_NETWORK_LOGO ? (
                <img src={`${networkLogo?.network_logo?.url}?r=${Math.random()}`} alt='network logo' />
              ) : (
                <p>Upload your network logo</p>
              )}
            </StyledImage>

            {HAS_NETWORK_LOGO && (
              <Button
                variant='outline-danger'
                onClick={() => {
                  setNetworkLogo({
                    network_logo: {
                      creator_id: user.id,
                      extension: 'png',
                      name: 'zitcha_brandmark_white.png',
                      path: '/Zitcha_Brandmark White.png',
                      storage: 'locally',
                      url: '/Zitcha_Brandmark White.png',
                    },
                  });
                  setColor('#000000');
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </FileContainer>
          <span>{errors?.network_logo && <span className='text-danger'>{errors?.network_logo[0]}</span>}</span>
          {uploadProgress > 0 && (
            <ProgressBar
              className='mt-2'
              animated
              now={uploadProgress}
              style={{
                width: '100%',
                maxWidth: '300px',
              }}
            />
          )}
        </Form.Group>
        <Form.Group>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1rem',
              gap: '10px',
            }}
          >
            <Label>Navigation bar color</Label>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id='info-popover' data-trigger='focus' className='topper'>
                  You must upload a network logo before you can select a navigation bar colour.
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faCircleInfo} size='sm' />
            </OverlayTrigger>
          </div>

          <ColorPicker color={color} setColor={handleColorChange} disabled={HAS_NETWORK_LOGO ? false : true} />
        </Form.Group>
        <span>{errors?.theme && <span className='text-danger'>{errors?.theme[0]}</span>}</span>

        <Row className='mb-3'>
          <Col>
            <Button
              variant='primary'
              className='mr-auto px-5 py-2 float-right'
              disabled={mutation.isLoading}
              type='submit'
            >
              <span
                className='small d-flex'
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {mutation.isLoading && (
                  <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' className='mr-2' />
                )}
                <span>Save Changes</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export { NetworkBranding };
const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  color: #6c757d;
  margin-bottom: 2.5rem;
`;
const StyledTitle = styled.div`
  font-weight: bold;
  h3 {
    font-size: 22px;
  }
  color: #000;
  text-align: left;
  width: 100%;
`;
const Label = styled.span`
  font-size: 16px;
  display: block;
  font-weight: 700;
  line-height: 18.75px;
  text-align: left;
  color: #343a40;
`;

const FileContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  .custom-file {
    max-width: 300px;
  }
`;
const StyledImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  min-height: 37px;
  padding: 8px;
  img {
    object-fit: contain;
    width: 100%;
    height: 40px;
    max-height: 100%;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: #b9b9c0;
  }
`;

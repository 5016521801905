import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useLocation } from 'react-router-dom';
import { Plan, useGetPlan } from '../../../lib/api/ad-management';
import { ViewMode } from './ViewModeType';
import { ViewPlanAdSetsTable } from './reviewAdSets/ViewPlanAdSetsTable';
import { PlanDetailsSection } from './PlanDetailsSection';
import { PlanViewHeadingSection } from './PlanViewHeadingSection';
import { useWallets } from 'v2/lib/hooks/useWallets';
import Wallet from 'v2/Types/Wallets';
import { EditPlanTables } from './editAdSets/EditPlanTables';
import { AdSet } from 'v2/lib/api/ad-management';
import { calculateTotalBudget } from 'v2/utils/newPlanUtils';
import { SimpleLoadingScreen } from 'v2/components/SimpleLoadingScreen';

const findMatchingWallet = (wallets: Array<Wallet>, planData: Plan) => {
  return wallets.find((wallet) => wallet.id === planData.wallet?.id) || null;
};

// Clear new ad sets and set view mode to reviewing after successful propose
const onSuccessfulPropose = ({
  setNewAdSets,
  setViewMode,
}: {
  setNewAdSets: React.Dispatch<React.SetStateAction<Array<AdSet>>>;
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
}) => {
  setNewAdSets([]);
  setViewMode(ViewMode.REVIEWING);
};

export const PlanView = () => {
  const { planId } = useParams();
  const location = useLocation();
  const { isLoading, isError, data, error } = useGetPlan(planId as string);
  const planData: Plan = data?.data;
  const initialViewMode = location.state?.viewMode || ViewMode.REVIEWING;
  const [viewMode, setViewMode] = useState<ViewMode>(initialViewMode);

  //state for selected wallet and new Ad sets in edit mode
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
  const [newAdSets, setNewAdSets] = useState<Array<AdSet>>([]);

  const { wallets, isLoading: isWalletsLoading } = useWallets(planData?.supplier?.id as string);

  useEffect(() => {
    if (isWalletsLoading || !planData?.wallet?.id || !wallets) return;
    setSelectedWallet(findMatchingWallet(wallets, planData));
  }, [planData, wallets, isWalletsLoading, viewMode]);

  useEffect(() => {
    // Reinitialize state variables here
    setSelectedWallet(null);
    setNewAdSets([]);
  }, [viewMode]);

  const newAdSetsTotalValue = useMemo(() => {
    return calculateTotalBudget(newAdSets);
  }, [newAdSets]);

  const addNewAdSet = (adSet: AdSet) => setNewAdSets([...newAdSets, adSet]);
  const removeNewAdSet = (id: string) => setNewAdSets(newAdSets.filter((ad) => ad.id !== id));
  const updateNewAdSet = (adSet: AdSet) => setNewAdSets(newAdSets.map((row) => (row.id === adSet.id ? adSet : row)));

  if (isLoading) return <SimpleLoadingScreen />;
  if (isError) return <>An error occurred: {error?.message}</>;
  if (!planData || !planData.id) return <>No plan to display</>;

  return (
    <>
      <Helmet>
        <title>
          Plan - {planData.name || ''} - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <PlanViewHeadingSection
        mode={viewMode}
        setMode={setViewMode}
        plan={planData}
        selectedWallet={selectedWallet}
        newAdSets={newAdSets}
        onSuccessfulPropose={() => onSuccessfulPropose({ setNewAdSets, setViewMode })}
      />
      <PlanDetailsSection
        plan={planData}
        mode={viewMode}
        selectedWallet={selectedWallet}
        setSelectedWallet={setSelectedWallet}
        wallets={wallets}
        isWalletsLoading={isWalletsLoading}
        findMatchingWallet={findMatchingWallet}
        newAdSetsTotalValue={newAdSetsTotalValue}
      />
      {viewMode === ViewMode.REVIEWING ? (
        <ViewPlanAdSetsTable planId={planData.id} viewMode={viewMode} />
      ) : (
        <EditPlanTables
          planId={planData.id}
          newAdSets={newAdSets}
          addNewAdSet={addNewAdSet}
          removeNewAdSet={removeNewAdSet}
          updateNewAdSet={updateNewAdSet}
          setNewAdSets={setNewAdSets}
        />
      )}
    </>
  );
};

import { Box, Typography } from '@the-pistol/zitcha-component-library';
import React from 'react';
interface RenderCurrencyProps {
  amount: number | undefined;
  symbol?:
    | 'USD'
    | 'GPB'
    | 'EUR'
    | 'AUD'
    | 'CAD'
    | 'NZD'
    | 'JPY'
    | 'CNY'
    | 'INR'
    | 'RUB'
    | 'BRL'
    | 'MXN'
    | 'KRW'
    | 'TRY'
    | 'ZAR'
    | 'IDR'
    | 'CHF'
    | 'SEK'
    | 'NOK'
    | 'DKK'
    | 'CZK'
    | 'HUF'
    | 'PLN'
    | 'ILS'
    | 'THB'
    | 'MYR'
    | 'PHP'
    | 'SGD'
    | 'HKD'
    | 'TWD'
    | 'SAR'
    | 'AED'
    | 'QAR'
    | 'KWD'
    | 'OMR'
    | 'BHD'
    | 'JOD'
    | 'EGP'
    | 'LBP'
    | 'PKR'
    | 'BDT'
    | 'LKR'
    | 'NPR'
    | 'MMK'
    | 'VND'
    | 'KHR'
    | 'KZT'
    | 'UZS'
    | 'TJS'
    | 'AZN'
    | 'GEL'
    | 'AMD'
    | 'MNT'
    | 'AFN'
    | 'IRR'
    | 'IQD'
    | 'LYD'
    | 'DZD'
    | 'TND'
    | 'MAD'
    | 'SDG'
    | 'SYP'
    | 'YER'
    | 'SOS'
    | 'SLL'
    | 'LRD'
    | 'GMD'
    | 'GNF';
  label?: string;
}

export const RenderCurrency = ({ amount, symbol, label }: RenderCurrencyProps) => {
  const formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: symbol ?? 'USD' }).format(
    amount ?? 0
  );
  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{
        minWidth: '80px',
        textAlign: 'left',
      }}
    >
      {label && <Typography variant='caption'>{label}</Typography>}
      <Typography variant='subtitle1' gutterBottom>
        {amount && amount > 0 ? formatted : '-'}
      </Typography>
    </Box>
  );
};

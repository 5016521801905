import React from 'react';
import AllOrders from './app/AllOrders';
import ArchivedOrders from './app/ArchivedOrders';
import CreateOrder from './app/CreateOrder';
import LegacyDashboard from './app/Dashboard';
import EditOrder from './app/EditOrder';
import InviteSupplier from './app/InviteSupplier';
import InviteUser from './app/InviteUser';
import LinkOrg from './app/LinkOrg';
import LinkUser from './app/LinkUser';
import Login from './app/Login';
import LoginAdminAs from './app/LoginAdminAs';
import ProtectedRoutes from './app/ProtectedRoutes';
import EditOwnedSpace from './app/EditOwnedSpace';
import CreateOwnedSpace from './app/CreateOwnedSpace';
import Register from './app/Register';
import RegisterUser from './app/RegisterUser';
import ResetPassword from './app/ResetPassword';
import ResetPasswordConfirm from './app/ResetPasswordConfirm';
import SupplierSettings from './app/SupplierSettings';
import SuppliersGeneralSettings from './app/SuppliersGeneralSettings/SuppliersGeneralSettings';
import ViewOrder from './app/ViewOrder';
import OrganisationSettings from './app/OrganisationSettings';
import OrganisationChannels from './app/OrganisationChannels';
import OrganisationWebSettings from './app/OrganisationWebSettings';
import OrganisationBespokeSettings from './app/OrganisationBespokeSettings';
import OrganisationInStoreScreensSettings from './app/OrganisationSettings/Broadsign/OrganisationInStoreScreensSettings';
import CreateBespokeProduct from './app/CreateBespokeProduct';
import EditBespokeProduct from './app/EditBespokeProduct';
import OrganisationUserEdit from './app/OrganisationUserEdit';
import ListPackages from './app/ListPackages';
import CreatePackage from './app/CreatePackage';
import OrganisationRoleEdit from './app/OrganisationRoleEdit';
import RetailerWallets from './app/RetailerWallets';
import SingleWalletPage from './app/SingleWalletPage';
import EditPackage from './app/EditPackage';
import OrganisationInventoryHome from './features/inventory/OrganisationInventoryHome';
import OrganisationInventoryWebspaceDetails from './features/inventory/OrganisationInventoryWebspaceDetails';
import OrganisationInventoryAudienceDetails from './features/inventory/OrganisationInventoryAudienceDetails';
import { EditBrandStore } from './features/brand_stores/EditBrandStore';
import OrganisationBrandStoresHome from './features/brand_stores/OrganisationBrandStoresHome';
import BrandStoreConfigureForm from './features/brand_stores/create/BrandStoreConfigureForm';
import { PlansAdSetsView } from 'v2/features/plansAndAdSets/PlansAdSetsView';
import { NewPlanView } from 'v2/features/plansAndAdSets/NewPlanView';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { clearSession } from './features/session/sessionSlice';
import { Box, FactCheckIcon } from '@the-pistol/zitcha-component-library';
import { PlanView } from './v2/features/plansAndAdSets/PlanPage/PlanView';
import { BaseLayout } from './v2/Layouts/BaseLayout';
import { Dashboard } from 'v2/features/Dashboard/Dashboard';

const LogOut = () => {
  const dispatch = useDispatch();
  dispatch(clearSession());
  return <Navigate to='/login' replace />;
};

export const getRoutes = (featureFlags) => {
  const planningEnabled = featureFlags?.feature_planning;
  return [
    { path: '/login', element: <Login />, handle: { crumb: 'Login' } },
    { path: '/admin-login-as', element: <LoginAdminAs />, handle: { crumb: 'Admin Login' } },
    { path: '/register', element: <Register />, handle: { crumb: 'Register' } },
    { path: '/register/user', element: <RegisterUser />, handle: { crumb: 'Register User' } },
    { path: '/organisation/link', element: <LinkOrg />, handle: { crumb: 'Link Organisation' } },
    { path: '/user/link', element: <LinkUser />, handle: { crumb: 'Link User' } },
    { path: '/reset', element: <ResetPassword />, handle: { crumb: 'Reset Password' } },
    { path: '/reset/password/:token', element: <ResetPasswordConfirm />, handle: { crumb: 'Confirm Password Reset' } },
    { path: '/logout', element: <LogOut />, handle: { crumb: 'Log Out' } },
    {
      path: '/',
      element: <ProtectedRoutes />,
      children: [
        { index: true, element: planningEnabled ? <Dashboard /> : <LegacyDashboard />, handle: { crumb: 'Dashboard' } },
        { path: 'orders', element: <AllOrders />, handle: { crumb: 'All Orders' } },
        { path: 'orders/archive', element: <ArchivedOrders />, handle: { crumb: 'Archived Orders' } },
        { path: 'order/create', element: <CreateOrder />, handle: { crumb: 'Create Order' } },
        { path: 'order/:order_id', element: <ViewOrder />, handle: { crumb: 'View Order' } },
        { path: 'order/edit/:order_id', element: <EditOrder />, handle: { crumb: 'Edit Order' } },
        {
          path: 'brandstores/configure',
          element: <BrandStoreConfigureForm />,
          handle: { crumb: 'Configure Brand Store' },
        },
        {
          path: 'brandstores/configure/:order_id',
          element: <BrandStoreConfigureForm />,
          handle: { crumb: 'Configure Brand Store' },
        },
        { path: 'brandstores/home', element: <OrganisationBrandStoresHome />, handle: { crumb: 'Brand Stores' } },
        { path: 'brandstores/edit/:order_id', element: <EditBrandStore />, handle: { crumb: 'Edit Brand Store' } },
        { path: 'organisations/invite', element: <InviteSupplier />, handle: { crumb: 'Invite Supplier' } },
        { path: 'inventory/home', element: <OrganisationInventoryHome />, handle: { crumb: 'Inventory' } },
        {
          path: 'inventory/ownedspace/usage/:usage_id',
          element: <OrganisationInventoryWebspaceDetails />,
          handle: { crumb: 'Webspace Usage Details' },
        },
        {
          path: 'inventory/audience/usage/:audience_id',
          element: <OrganisationInventoryAudienceDetails />,
          handle: { crumb: 'Audience Usage Details' },
        },
        {
          path: 'organisation/settings',
          element: <OrganisationSettings />,
          handle: { crumb: 'Organisation Settings' },
        },
        { path: 'organisation/settings/:id', element: <OrganisationUserEdit />, handle: { crumb: 'Edit User' } },
        {
          path: 'organisation/channels',
          element: <OrganisationChannels />,
          handle: { crumb: 'Organisation Channels' },
        },
        { path: 'organisation/websettings', element: <OrganisationWebSettings />, handle: { crumb: 'Web Settings' } },
        {
          path: 'organisation/instorescreens-settings',
          element: <OrganisationInStoreScreensSettings />,
          handle: { crumb: 'In-Store Screens Settings' },
        },
        {
          path: 'organisation/bespokesettings',
          element: <OrganisationBespokeSettings />,
          handle: { crumb: 'Bespoke Settings' },
        },
        { path: 'organisation/role/:id', element: <OrganisationRoleEdit />, handle: { crumb: 'Edit Role' } },
        { path: 'wallets', element: <RetailerWallets />, handle: { crumb: 'Retailer Wallets' } },
        { path: 'wallet/:wallet_id', element: <SingleWalletPage />, handle: { crumb: 'Single Wallet Page' } },
        {
          path: 'organisation/websettings/edit/:owned_space_id',
          element: <EditOwnedSpace />,
          handle: { crumb: 'Edit Owned Space' },
        },
        {
          path: 'organisation/websettings/create/:placement/:format/:subformat',
          element: <CreateOwnedSpace />,
          handle: { crumb: 'Create Owned Space' },
        },
        {
          path: 'settings/suppliers',
          element: <SuppliersGeneralSettings />,
          handle: { crumb: 'Suppliers General Settings' },
        },
        {
          path: 'settings/suppliers/:organisation_id',
          element: <SupplierSettings />,
          handle: { crumb: 'Supplier Settings' },
        },
        { path: 'packages', element: <ListPackages />, handle: { crumb: 'List Packages' } },
        { path: 'packages/create', element: <CreatePackage />, handle: { crumb: 'Create Package' } },
        { path: 'packages/:package_id/edit', element: <EditPackage />, handle: { crumb: 'Edit Package' } },
        {
          path: 'organisation/bespokesettings/create',
          element: <CreateBespokeProduct />,
          handle: { crumb: 'Create Bespoke Product' },
        },
        {
          path: 'organisation/bespokesettings/:bespoke_id/edit',
          element: <EditBespokeProduct />,
          handle: { crumb: 'Edit Bespoke Product' },
        },
        { path: 'users/invite', element: <InviteUser />, handle: { crumb: 'Invite User' } },
        ...(planningEnabled
          ? [
              {
                path: 'plans-ad-sets',
                element: <BaseLayout />,
                handle: {
                  crumb: (
                    <Box display='flex' alignItems='center' gap={1}>
                      <FactCheckIcon />
                      <span>Plans ad sets</span>
                    </Box>
                  ),
                },
                children: [
                  {
                    index: true,
                    element: <PlansAdSetsView />,
                  },
                  {
                    path: 'new-plan',
                    element: <NewPlanView />,
                    handle: { crumb: 'New plan' },
                  },
                  {
                    path: ':planId',
                    element: <PlanView />,
                    handle: { crumb: 'Plan' },
                  },
                ],
              },
            ]
          : []),
      ],
    },
  ];
};

import { Box } from '@the-pistol/zitcha-component-library';
import React from 'react';

export const ActionsRequired = () => {
  return (
    <Box height={200} borderColor={'grey'} border={1} p={2}>
      Actions Required not implemented
    </Box>
  );
};

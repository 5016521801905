import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import axios from '../../axios';
import { NETWORK_BRANDING } from './queryKeys';
import { useSelector } from 'react-redux';
import { selectSession, selectUser } from '../../features/session/sessionSlice';
import { useLocation } from 'react-router-dom';
import { selectOrder } from 'features/order_form/orderFormSlice';
import { selectBrandStoreOrder } from 'features/brand_stores/state_management/BrandStoreSlice';

const initialTheme = {
  settings: {
    network_logo: {
      url: '/Zitcha_Brandmark White.png',
      creator_id: '',
      path: '',
      storage: '',
      extension: '',
    },
    theme: {
      primary_colour: '#000000',
    },
  },
};

const getTheme = async (
  org_id: string,
  isRetailer: boolean,
  isEditingBrandStores: boolean,
  isManagingOrders?: boolean
) => {
  if (!org_id) return { data: initialTheme };
  if (isRetailer) {
    return axios.get(`/organisations/${org_id}/network_branding`);
  }
  const shouldFetchTheme = isManagingOrders || isEditingBrandStores;

  if (shouldFetchTheme) {
    return axios.get(`/organisations/${org_id}/network_branding`);
  } else {
    return { data: initialTheme };
  }
};

export default function useNetworkBranding() {
  const session = useSelector(selectSession);
  const organisation = session?.user?.active_organisation;
  const user = useSelector(selectUser);
  const isRetailer = user?.active_organisation?.is_retailer;
  const queryClient = useQueryClient();
  const location = useLocation();
  const [orgId, setOrgId] = useState<string>('');
  const order = useSelector(selectOrder);
  const brandStoreOrder = useSelector(selectBrandStoreOrder);
  const isManagingOrders = /\border\b/.test(location.pathname); // viewing order | create order | edit order cases
  const isEditingBrandStores = /\bbrandstores\/edit\b/.test(location.pathname); // brandstores edit case

  useEffect(() => {
    if (isRetailer) {
      setOrgId(organisation?.id);
    } else {
      if (isManagingOrders) {
        setOrgId(order.owner_id);
      } else if (isEditingBrandStores) {
        setOrgId(brandStoreOrder?.owner_id);
      }
    }
  }, [order, isManagingOrders, organisation?.id, isRetailer, brandStoreOrder, isEditingBrandStores]);

  const { data, isError, isInitialLoading, isLoading } = useQuery({
    queryKey: [NETWORK_BRANDING, orgId, isEditingBrandStores, isManagingOrders],
    queryFn: async () => {
      try {
        const res = await getTheme(orgId, isRetailer, isEditingBrandStores, isManagingOrders);
        return res?.data;
      } catch (error) {
        queryClient.setQueryData([NETWORK_BRANDING, orgId, isEditingBrandStores, isManagingOrders], initialTheme); // default to zitcha theme
        throw error;
      }
    },
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    retryOnMount: false,
    retryOnReconnect: false,
  });

  return {
    data,
    isError,
    isLoading,
    isInitialLoading,
  };
}
type NetworkLogo = { creator_id: string; extension: string; name: string; path: string; storage: string; url: string };
export const useNetworkBrandingMutation = () => {
  const session = useSelector(selectSession);
  const organisation = session?.user.active_organisation;
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data: { network_logo: NetworkLogo; theme: { primary_colour: string } }) => axios.put(`/network_branding`, data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: [NETWORK_BRANDING, organisation?.id] });
      const snapshot = queryClient.getQueryData([NETWORK_BRANDING, organisation?.id]);
      queryClient.setQueryData([NETWORK_BRANDING, organisation?.id], () => ({
        settings: {
          network_logo: { ...data.network_logo },
          theme: { ...data.theme },
        },
      }));
      return { snapshot };
    },
    onError: async () => {
      await queryClient.invalidateQueries([NETWORK_BRANDING, organisation?.id]);
      toast.error('Error updating network branding', { bodyClassName: 'network_branding_toast' });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([NETWORK_BRANDING, organisation?.id]);
      toast.success('Network branding updated successfully', { bodyClassName: 'network_branding_toast' });
    },
  });

  return mutation;
};

import React, { useEffect, useState } from 'react';
import { Modal } from '@the-pistol/zitcha-component-library';
import {
  Typography,
  Autocomplete,
  DriveFileRenameOutlineIcon,
  StoreIcon,
  MenuItem,
  AccountBalanceWalletIcon,
  EditIcon,
  SendIcon,
  InputAdornment,
  TextField,
  Button,
} from '@the-pistol/zitcha-component-library';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cachedFetchBrands } from 'features/brands/brandsSlice';
import { usePlanActions } from 'v2/lib/hooks/usePlanActions';
import { useBrands } from 'v2/lib/hooks/useBrands';
import { useWallets } from 'v2/lib/hooks/useWallets';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

interface NewPlanModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface NewPlanModalState {
  name: string | null;
  brandId: string | null;
  walletId: string | null;
}

const emptyState: NewPlanModalState = {
  name: null,
  brandId: null,
  walletId: null,
};

export const NewPlanModal = (props: NewPlanModalProps) => {
  const dispatch = useDispatch();
  const { proposePlan } = usePlanActions();
  const [newPlan, setNewPlan] = useState<NewPlanModalState>(emptyState);

  const navigate = useNavigate();

  const organisation = useUserOrganisation();

  const brands = useBrands();
  const { wallets: brandWallets, isLoading: isBrandWalletsLoading } = useWallets(newPlan.brandId || null);

  useEffect(() => {
    dispatch(cachedFetchBrands());
  }, []);

  const updatePlanData = (key: keyof NewPlanModalState, value: string) => setNewPlan({ ...newPlan, [key]: value });

  const handleProposePlan = () => {
    props.onClose();
    proposePlan(newPlan, organisation);
    setNewPlan(emptyState);
  };

  const handleStartEditingPlan = () => {
    props.onClose();

    navigate('/plans-ad-sets/new-plan', { state: newPlan, replace: true });
    setNewPlan(emptyState);
  };

  return (
    <div>
      <Modal open={props.isOpen} size='medium' title='New plan' onClose={props.onClose} className='twd-min-w-[400px]'>
        <div>
          <Typography variant='body1'>
            Start editing a new plan, or propose a brand to fill out based on their preferences.
          </Typography>
          {/* Plan Name */}
          <div className='twd-mt-8'>
            <TextField
              className='twd-w-full'
              id='plan-name'
              label='Name your plan'
              variant='standard'
              value={newPlan.name ?? ''}
              onChange={(event) => updatePlanData('name', event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <DriveFileRenameOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {/* Brand selection */}
          <div className='twd-mt-8'>
            <TextField
              onChange={(event) => updatePlanData('brandId', event.target.value)}
              id='brand-select'
              className='twd-w-full'
              select
              label='Choose a brand'
              variant='standard'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <StoreIcon />
                  </InputAdornment>
                ),
              }}
            >
              {brands.map((supplier) => (
                <MenuItem key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
          {/* Wallet selection */}
          <div className='twd-mt-8'>
            <Autocomplete
              id='wallet-select'
              options={brandWallets}
              getOptionLabel={(option) => option.name}
              loading={isBrandWalletsLoading}
              onChange={(event, newValue) => updatePlanData('walletId', newValue?.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Choose a wallet'
                  variant='standard'
                  helperText="If you can't see a related wallet, visit the brand's wallet settings"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AccountBalanceWalletIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>

          {/* Action buttons */}
          <div className='twd-mt-8 twd-flex'>
            <Button variant='text' className='w-5/12 mr-3' onClick={handleStartEditingPlan}>
              <EditIcon className='twd-mr-2' />
              START EDITING PLAN
            </Button>
            <Button className='w-5/12' onClick={handleProposePlan}>
              PROPOSE A PLAN
              <SendIcon className='twd-ml-2' />
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import {
  AdSet,
  getGetPlanQueryKey,
  PlanUpdateForm,
  useCreateAdSetFromBundle,
  useCreatePlan,
  useTransitionPlan,
  useUpdateAdsInAdSet,
  useUpdatePlan,
} from 'v2/lib/api/ad-management';
import { NewPlan } from 'v2/Types/Plans';
import { BodyType } from '../axios/axiosMainInstance';

interface Organisation {
  id: string;
}

export const usePlanActions = () => {
  const navigate = useNavigate();
  const createPlan = useCreatePlan();
  const transitionPlan = useTransitionPlan();
  const adAdSet = useCreateAdSetFromBundle();
  const queryClient = useQueryClient();
  const { mutateAsync: mutateAdsInAdSet } = useUpdateAdsInAdSet();

  const cancelPlan = () => navigate('/plans-ad-sets');

  const savePlan = async (plan: NewPlan, organisation: Organisation, adSets?: Array<AdSet>) => {
    const createdPlan = await createPlan.mutateAsync({
      data: {
        name: plan.name,
        owner_organisation_id: organisation.id,
        supplier_organisation_id: plan.brandId,
        wallet_id: plan.walletId,
      },
    });

    if (adSets && createdPlan.id) {
      await addAdSets(adSets, createdPlan.id);
    }

    navigate('/plans-ad-sets/' + createdPlan.id);
  };

  const proposePlan = async (plan: NewPlan, organisation: Organisation, adSets?: Array<AdSet>) => {
    const createdPlan = await createPlan.mutateAsync({
      data: {
        name: plan.name,
        owner_organisation_id: organisation.id,
        supplier_organisation_id: plan.brandId,
        wallet_id: plan.walletId,
      },
    });

    if (adSets && createdPlan.id) {
      await addAdSets(adSets, createdPlan.id);
    }

    transitionPlan.mutate(
      { id: createdPlan.id, data: { status: 'proposed' } },
      { onSuccess: () => navigate('/plans-ad-sets', { replace: true }) }
    );
  };

  const agreePlan = async (planId: string) => {
    await transitionPlan.mutateAsync(
      { id: planId, data: { status: 'agreed' } },
      {
        onSuccess: () => {
          const getPlanQueryKey = getGetPlanQueryKey(planId);
          queryClient.invalidateQueries({ queryKey: getPlanQueryKey });
        },
      }
    );
  };

  const updatePlan = useUpdatePlan();

  const modifyPlan = async (planId: string, planUpdateForm: BodyType<PlanUpdateForm>) => {
    await updatePlan.mutateAsync(
      { id: planId, data: planUpdateForm },
      {
        onSuccess: () => {
          const getPlanQueryKey = getGetPlanQueryKey(planId);
          queryClient.invalidateQueries({ queryKey: getPlanQueryKey });
        },
      }
    );
  };

  // Add ad sets to the plan, and add ads to the new ad sets (this is needed as when the ad sets are created in the BE, they are given a new ID)
  const addAdSets = async (adSets: Array<AdSet>, planId: string) => {
    return await Promise.all(adSets.map((adSet) => {
      adAdSet.mutateAsync({ planId, bundleId: adSet.bundleIds?.[0] }).then((createdAdSet) => {
        if (adSet.ads?.length && adSet.id) {
          mutateAdsInAdSet({
            id: createdAdSet?.id,
            data: {
              ads: adSet.ads.map((ad) => ({
                id: ad.id ?? undefined,
                name: ad.name || '',
                fields: [
                  {
                    header_text: ad?.fields?.header_text,
                    link: ad?.fields?.link,
                    assets: ad?.fields?.assets,
                  },
                ],
              })),
            },
          });
        }
      });
    }));
  };

  return {
    cancelPlan,
    savePlan,
    proposePlan,
    agreePlan,
    modifyPlan,
    addAdSets,
    processing: createPlan.isLoading || transitionPlan.isLoading || adAdSet.isLoading,
  };
};

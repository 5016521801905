import React, { useState } from 'react';
import {
  Box,
  Typography,
  Chip,
  IconButton,
  TextField,
  ContentCopyIcon,
  CheckIcon,
  Divider,
  Autocomplete,
} from '@the-pistol/zitcha-component-library';
import { Plan, PlanStatus } from '../../../lib/api/ad-management';
import { statusNameMapping, statusChipVariantMap, statusChipColorMap } from 'v2/utils/planStatusUtils';
import { formatDisplayDate, formatRecentDate } from 'helpers/DateHelpers';
import Wallet from 'v2/Types/Wallets';
import { ViewMode } from './ViewModeType';

interface PlanDetailsSectionProps {
  plan: Plan;
  mode: ViewMode;
  selectedWallet: Wallet | null;
  setSelectedWallet: (wallet: Wallet | null) => void;
  wallets: Array<Wallet>;
  isWalletsLoading: boolean;
  findMatchingWallet: (wallets: Array<Wallet>, planData: Plan) => Wallet | null;
  newAdSetsTotalValue: number;
}

export const PlanDetailsSection: React.FC<PlanDetailsSectionProps> = ({
  plan,
  mode,
  selectedWallet,
  setSelectedWallet,
  wallets,
  isWalletsLoading,
  findMatchingWallet,
  newAdSetsTotalValue,
}) => {
  const status = plan.status as PlanStatus;
  const statusName = statusNameMapping[status];
  const variant = statusChipVariantMap[status];
  const color = statusChipColorMap[status];
  const updatedAt = plan.updatedAt ? new Date(plan.updatedAt) : new Date();
  const createdAt = plan.createdAt ? new Date(plan.createdAt) : new Date();
  const planValue = (plan?.planValue || 0) + newAdSetsTotalValue || 0; //todo get plan value from BE
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(plan.id || '');
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 1000);
    } catch (err) {
      //eslint-disable-next-line no-console
      console.error('TODO: Error handling: Failed to copy text: ', err);
    }
  };

  const DetailItem: React.FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => (
    <Box display='flex' alignItems='center' gap={1}>
      <Box>
        <Typography variant='overline'>{label}</Typography>
      </Box>
      {value}
    </Box>
  );

  if (isWalletsLoading) return <Box>Loading...</Box>;

  return (
    <Box display='flex' justifyContent='space-between' my={3}>
      <Box id='plan-info' display='flex' flexWrap='wrap' gap={1} alignItems='center'>
        <DetailItem
          label='Plan status'
          value={<Chip label={statusName} variant={variant} color={color} size='small' />}
        />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem
          label='Plan ID'
          value={
            <IconButton color='default' size='small' ariaLabel={'Copy plan ID'} onClick={copyToClipboard}>
              {copySuccess ? <CheckIcon /> : <ContentCopyIcon />}
            </IconButton>
          }
        />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem
          label='Last modified'
          value={<Typography variant='body1'>{formatRecentDate(updatedAt)}</Typography>}
        />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem
          label='Plan created at'
          value={<Typography variant='body1'>{formatDisplayDate(createdAt)}</Typography>}
        />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem label='Plan value' value={<Typography variant='body1'>£{planValue || ' - '}</Typography>} />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem
          label='Remaining wallet balance'
          value={
            <Typography variant='body1'>
              {' '}
              {/*todo add currency formatting*/}
              {selectedWallet ? `£${selectedWallet?.available_balance - planValue}` : 'no wallet'}
            </Typography>
          }
        />
      </Box>
      {mode === ViewMode.EDITING ? (
        //todo add type to options
        <Autocomplete
          options={wallets}
          getOptionLabel={(option) => (option ? `${option.name}: £${option.available_balance}` : 'no wallet')}
          value={selectedWallet}
          onChange={(event, newValue) => {
            if (newValue === null) {
              // If the Autocomplete input is cleared, use findMatchingWallet to set selectedWallet
              setSelectedWallet(findMatchingWallet(wallets, plan));
            } else {
              setSelectedWallet(newValue);
            }
          }}
          renderInput={(params) => <TextField {...params} variant='standard' label='Wallet' />}
          sx={{ minWidth: 250 }}
        />
      ) : (
        <TextField
          id='plan-wallet'
          label='Wallet'
          variant='standard'
          size='small'
          sx={{ minWidth: '250px' }}
          value={selectedWallet ? `${selectedWallet.name}: £${selectedWallet.available_balance}` : 'no wallet'}
        />
      )}
    </Box>
  );
};

import {
  useTheme,
  MenuIcon,
  MenuOpenIcon,
  Box,
  Typography,
  IconButton,
  AppBar,
} from '@the-pistol/zitcha-component-library';
import React, { useContext } from 'react';
import { NavigationContext } from './Chrome';
import { UserName } from './UserDropdownMenu';
interface NavBarProps {
  page: string;
}

function NavBar({ page }: NavBarProps) {
  const { drawerWidth, drawerSizes, toggleDrawerWidth, user } = useContext(NavigationContext);
  const theme = useTheme();
  return (
    <AppBar
      isPrimaryNav={true}
      sx={{
        backgroundColor: theme.palette.primary.main,
        position: 'fixed',
        color: theme.palette.primary.contrastText,
      }}
    >
      <Box
        component='nav'
        display='flex'
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1rem',
          height: '4rem',
        }}
      >
        <Box
          component='div'
          display='flex'
          sx={{
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <IconButton
            data-testid='menu'
            aria-label='menu'
            onClick={toggleDrawerWidth}
            size='medium'
            sx={{
              color: theme.palette.primary.contrastText,
            }}
          >
            {drawerWidth === drawerSizes.expanded ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <Box>
            <img
              src={user?.active_organisation?.settings?.logo?.url || '/Zitcha_Brandmark White.png'}
              alt={user?.active_organisation?.name}
              style={{
                objectFit: 'contain',
                maxHeight: '24px',
                width: 'auto',
                maxWidth: '108.7px',
              }}
            />
          </Box>
          <Typography variant='body1'>{page}</Typography>
        </Box>
        <UserName />
      </Box>
    </AppBar>
  );
}
export { NavBar };

import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Typography,
  Alert,
  Autocomplete,
  Box,
  Chip,
  Stack,
  TextField,
  DeleteIcon,
  SaveIcon,
  SendIcon,
} from '@the-pistol/zitcha-component-library';
import { Helmet } from 'react-helmet-async';
import { EditableField } from 'v2/components/EditableField/EditableField';
import Brand from 'v2/Types/Brands';
import Wallet from 'v2/Types/Wallets';
import { NewPlan } from 'v2/Types/Plans';
import { usePlanActions } from 'v2/lib/hooks/usePlanActions';
import { useLocation } from 'react-router-dom';
import { useBrands } from 'v2/lib/hooks/useBrands';
import { useWallets } from 'v2/lib/hooks/useWallets';
import { NewPlanAdSets } from './NewPlanAdSets';
import { type AdSet } from 'v2/lib/api/ad-management';
import { RenderCurrency } from 'v2/components/RenderCurrency/RenderCurrency';
import { calculateTotalBudget } from 'v2/utils/newPlanUtils';
import { v4 as uuidv4 } from 'uuid';
import { ViewMode } from './PlanPage/ViewModeType';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

const planInitialState: NewPlan = {
  id: undefined,
  name: null,
  brandId: null,
  walletId: null,
  discount: null,
  planValue: null,
  adSets: null,
};

export function NewPlanView(): React.ReactElement {
  const { state } = useLocation();
  const [plan, setPlan] = useState<NewPlan>({ ...planInitialState, ...state });
  const [remainingWalletBalance, setRemainingWalletBalance] = useState<number>(0);
  const [newAdSets, setNewAdSets] = useState<Array<AdSet>>([{ id: uuidv4() }]);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const { cancelPlan, savePlan, proposePlan } = usePlanActions();
  const organisation = useUserOrganisation();
  const brands = useBrands();
  const { wallets, isLoading: isWalletsLoading } = useWallets(plan.brandId);

  const selectBrand = (brand: Brand) => setPlan({ ...plan, brandId: brand.id });
  const selectWallet = (wallet: Wallet) => setPlan({ ...plan, walletId: wallet.id });
  const selectDiscount = (discount: unknown) => setPlan({ ...plan, discount }); //<-revisit this line
  const updatePlanName = (name: string) => setPlan({ ...plan, name });

  const addAdSet = (adSet: AdSet) => setNewAdSets([...newAdSets, adSet]);
  const removeAdSet = (id: string) => setNewAdSets(newAdSets.filter((ad) => ad.id !== id));
  const updateAdSet = (adSet: AdSet) => setNewAdSets(newAdSets.map((row) => (row.id === adSet.id ? adSet : row)));

  useEffect(() => {
    if (plan.walletId) {
      const wallet = wallets.find((wallet: Wallet) => wallet.id === plan.walletId);
      // need to calculate the remaining balance based on the plan value
      setRemainingWalletBalance(wallet?.available_balance ?? 0);
    }
  }, [plan.walletId]);

  useEffect(() => {
    const allAdSetsHaveBundleId = newAdSets.every((adSet) => adSet?.bundleIds && adSet.bundleIds?.length > 0);
    if (plan.brandId && plan.walletId && allAdSetsHaveBundleId) {
      setSaveEnabled(true);
    } else if (saveEnabled) {
      setSaveEnabled(false);
    }
  }, [plan, newAdSets]);

  const planValue = useMemo(() => {
    return calculateTotalBudget(newAdSets);
  }, [newAdSets]);

  return (
    <>
      <Helmet>
        <title>New plan - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        my={2}
        sx={{
          minHeight: '64px',
        }}
      >
        <Box display='flex' sx={{ alignItems: 'center', gap: 2 }}>
          <Typography variant='h4' data-testid='pageHeading'>
            New plan
          </Typography>
          <EditableField initialValue={plan.name ?? 'Untitled'} onChange={updatePlanName} editable={true} />
        </Box>

        <Box gap={2} display='flex'>
          <Button
            variant='text'
            color='error'
            onClick={cancelPlan}
            startIcon={<DeleteIcon />}
            data-testid='cancel-plan'
          >
            Cancel Plan
          </Button>
          <Button
            variant='text'
            disabled={!saveEnabled}
            startIcon={<SaveIcon />}
            onClick={() => savePlan(plan, organisation, newAdSets)}
            data-testid='save-plan'
          >
            Save Draft
          </Button>
          <Button
            disabled={!saveEnabled}
            endIcon={<SendIcon />}
            onClick={() => proposePlan(plan, organisation, newAdSets)}
            data-testid='propose-plan'
          >
            Propose Plan
          </Button>
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between' my={2}>
        <Typography variant='h5'>
          Plan status <Chip label='Draft' />
        </Typography>
        <Stack direction='row' spacing={2}>
          <Autocomplete
            data-testid='autocomplete-brand'
            onChange={(_, newValue: Brand) => selectBrand(newValue)}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                variant='standard'
                label='Brand'
                sx={{ minWidth: '225px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            options={brands}
            value={brands.find((brand) => brand.id === plan.brandId) ?? undefined}
          />
          <Autocomplete
            data-testid='autocomplete-wallet'
            onChange={(_, newValue: Wallet) => selectWallet(newValue)}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                variant='standard'
                label='Wallet'
                sx={{ minWidth: '225px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            getOptionKey={(option) => option?.id}
            getOptionLabel={(option) => option?.name}
            options={wallets}
            loading={isWalletsLoading}
            size='medium'
            value={wallets.find((wallet: Wallet) => wallet?.id === plan.walletId) ?? undefined}
          />
          <Autocomplete
            onChange={(_, newValue) => selectDiscount(newValue)}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                variant='standard'
                label='Discount'
                sx={{ minWidth: '225px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            options={brands}
          />
          <Box display='flex' flexDirection='row' gap={2}>
            <RenderCurrency amount={planValue} label='Plan value' />
            <RenderCurrency amount={remainingWalletBalance} label='Remaining wallet balance' />
          </Box>
        </Stack>
      </Box>
      <Alert
        variant='filled'
        severity='info'
        sx={{
          marginBottom: '2rem',
          marginTop: '2rem',
        }}
      >
        Schedule, media type and placement are the minimum inputs required to request an ad set.
      </Alert>
      <NewPlanAdSets
        organisationId={organisation.id}
        adSets={newAdSets}
        addAdSet={addAdSet}
        removeAdSet={removeAdSet}
        updateAdSet={updateAdSet}
        setAdSets={setNewAdSets}
        viewMode={ViewMode.NEW}
      />
    </>
  );
}

import { useSelector } from 'react-redux';
import { selectAdTypes } from 'features/session/sessionSlice';

export const useAdTypes = () => {
  const adTypes = useSelector(selectAdTypes);
  const adTypesOptions: Array<{
    id: string;
    name: string;
  }> = adTypes.map((adType: { id: string; label: string }) => ({
    id: adType.id,
    name: adType.label,
  }));
  return adTypesOptions;
};

import { useTheme, AddIcon, Box, Button, Typography } from '@the-pistol/zitcha-component-library';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function AddPlanBtn({ onlyIcon = false }: { onlyIcon?: boolean }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleNewPlanClick = () => {
    navigate('/plans-ad-sets/new-plan');
  };
  return (
    <Box
      sx={{
        marginTop: '1rem',
        marginBottom: '2rem',
      }}
    >
      {onlyIcon ? (
        <Button
          variant='contained'
          color='primary'
          sx={{
            minWidth: '2.65rem',
            width: '2.65rem',
            height: '2.65rem',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          onClick={handleNewPlanClick}
        >
          <AddIcon />
        </Button>
      ) : (
        <Button
          color='primary'
          variant='contained'
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            width: '100%',
            color: theme.palette.primary.contrastText,
            justifyContent: 'center',
            fontWeight: '400',
            lineHeight: '150%',
            letterSpacing: '0.00938rem',
            padding: '.65rem 1.375rem',
            height: '2.65rem',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          onClick={handleNewPlanClick}
        >
          <AddIcon />
          <Typography variant='button'>New Plan</Typography>
        </Button>
      )}
    </Box>
  );
}
export { AddPlanBtn };

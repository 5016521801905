import React from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Box, Link, HomeIcon } from '@the-pistol/zitcha-component-library';

interface DynamicBreadcrumbsProps {
  showHomeIcon?: boolean;
}

interface BreadcrumbHandle {
  crumb: JSX.Element;
}

interface BreadcrumbMatch {
  id: string;
  pathname: string;
  params: any;
  handle?: BreadcrumbHandle;
}

export const DynamicBreadcrumbs = ({ showHomeIcon = false }: DynamicBreadcrumbsProps) => {
  const matches: Array<BreadcrumbMatch> = useMatches();
  const navigate = useNavigate();

  // Create breadcrumb links from matches
  // If a route does not have a 'crumb' property on its 'handle' object, it will not be included in the breadcrumb navigation.
  const breadcrumbLinks: Array<JSX.Element> = matches
    .filter((match: BreadcrumbMatch) => match.handle?.crumb) // Ensure there's a breadcrumb handle
    .map((match: BreadcrumbMatch, index: number) => {
      const last: boolean = index === matches.length - 1;
      const to: string = match.pathname;
      // Execute crumb function or use static crumb
      const breadcrumbContent: JSX.Element =
        typeof match.handle?.crumb === 'function' ? match.handle.crumb() : match.handle.crumb;

      return (
        <Link
          underline='hover'
          color='inherit'
          onClick={() => !last && navigate(to)}
          key={to}
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          {breadcrumbContent}
        </Link>
      );
    });

  return (
    <Box mb={2}>
      <Breadcrumbs ariaLabel='breadcrumbs'>
        {showHomeIcon && (
          <Link underline='hover' color='inherit' onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
            <Box display='flex' alignItems='center'>
              <HomeIcon />
            </Box>
          </Link>
        )}
        {breadcrumbLinks}
      </Breadcrumbs>
    </Box>
  );
};

import React from 'react';
import { Location, useGetBundles } from '../../lib/api/ad-management';
import { ListItemButton } from '@mui/material';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

interface PlacementListItemBtnProps {
  onClick: (id: Partial<Location>) => void;
  location: Location;
  selected: boolean;
}
const key = 'bundle';
export const PlacementListItemBtn = ({ onClick, location, selected }: PlacementListItemBtnProps) => {
  const organisation = useUserOrganisation();
  const { data, isLoading } = useGetBundles(
    { organisation_id: organisation.id, location_id: location?.id ?? undefined },
    { query: { enabled: true, queryKey: [key, location.id] } }
  );

  const disabled = data?.data?.length === 0 || isLoading;
  return (
    <ListItemButton selected={selected} onClick={() => onClick(location)} disabled={disabled}>
      {location.name}
    </ListItemButton>
  );
};

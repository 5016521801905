import React from 'react';
import { useBlocker } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface OnLeaveModalProps {
  shouldBlock: boolean;
}

const OnLeaveModal: React.FC<OnLeaveModalProps> = ({ shouldBlock }) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => shouldBlock && currentLocation.pathname !== nextLocation.pathname
  );

  const isBlocked = blocker.state === 'blocked';

  return (
    <Modal
      show={shouldBlock && isBlocked}
      onHide={() => blocker.reset()}
      style={{
        zIndex: 1000000, // This is to make sure the modal is on top of everything
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Unsaved Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you wish to leave the edit mode without saving changes?</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => blocker.reset()}>
          Stay
        </Button>
        <Button variant='danger' onClick={() => blocker.proceed()}>
          Leave
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OnLeaveModal;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import { selectDialog } from './app/appSlice';
import { ToastContainer } from 'react-toastify';
import AppDialog from './app/Dialog';
import { randomRange } from './lib/random';
import { isLoggedIn, refreshSession } from './features/session/sessionSlice';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import { trackPageView } from './helpers/segment';
import { HelmetProvider } from 'react-helmet-async';
import StyleSheetManagerProvider from './providers/StyleSheetManagerProvider';
import { CustomThemeProvider } from '@the-pistol/zitcha-component-library';
import { getRoutes } from './routes';
import '@the-pistol/zitcha-component-library/dist/style.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useGetFeatureFlags } from 'v2/lib/api/system-api';

const PageView = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname]);
  return <></>;
};

const App = () => {
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const dialog = useSelector(selectDialog);
  const { data: featureFlags } = useGetFeatureFlags();

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (isLoggedIn(session)) {
          dispatch(refreshSession());
        }
      },
      randomRange(3, 5) * 60 * 1000
    );
    return () => {
      clearInterval(interval);
    };
  }, [session, dispatch]);

  const routes = getRoutes(featureFlags);
  const router = createBrowserRouter(routes);

  // Function to add opacity to hex colors
  const addOpacity = (color, opacity) => {
    const r = parseInt(color.slice(1, 3), 16),
      g = parseInt(color.slice(3, 5), 16),
      b = parseInt(color.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const hardcodedOcadoTheme = featureFlags?.feature_planning
    ? {
        palette: {
          primary: {
            main: '#4D216D', //Ocado Purple
            dark: '#2E1A4A', //todo check this hex
          },
          warning: {
            main: '#F1C500',
            contrast: addOpacity('#000000', 0.087), //todo use contrast for chip text color
          },
          action: {
            hover: addOpacity('#4D216D', 0.04),
            selected: addOpacity('#4D216D', 0.08),
            focus: addOpacity('#4D216D', 0.12),
          },
          text: {
            primary: addOpacity('#000000', 0.87),
            secondary: addOpacity('#000000', 0.6),
            disabled: addOpacity('#000000', 0.38),
          },
        },
        typography: {
          overline: {
            lineHeight: 1.5, // Match the body1 line-height
          },
        },
      }
    : {};

  return (
    <StyleSheetManagerProvider>
      <HelmetProvider>
        <CustomThemeProvider themeOverrides={hardcodedOcadoTheme}>
          <RouterProvider router={router}>
            <PageView />
            {!!dialog && <AppDialog dialog={dialog} />}
            <ToastContainer autoClose={5000} />
          </RouterProvider>
          <ReactQueryDevtools initialIsOpen={true} buttonPosition='bottom-left' />
        </CustomThemeProvider>
      </HelmetProvider>
    </StyleSheetManagerProvider>
  );
};

export default App;

import {
  useTheme,
  ExpandLessIcon,
  ExpandMoreIcon,
  SvgIconProps,
  Collapse,
  Button,
  List,
  IconButton,
  Typography
} from '@the-pistol/zitcha-component-library';
import React, { useContext } from 'react';
import { NavigationContext } from './Chrome';
import { useLocation } from 'react-router-dom';

type Icon = React.ElementType<SvgIconProps>;
interface CollapsibleProps {
  Icon: Icon;
  title: string;
  onlyIcon: boolean;
  children: React.ReactNode;
  openState: boolean;
  setOpenState: React.Dispatch<React.SetStateAction<boolean>>;
  childLinks: Array<string>;
}

const Collapsible = ({ Icon, title, onlyIcon, children, openState, setOpenState, childLinks }: CollapsibleProps) => {
  const { drawerSizes, setDrawerWidth } = useContext(NavigationContext);
  const theme = useTheme();
  const location = useLocation();
  const handleClick = async () => {
    setOpenState(!openState);
  };

  const openCollapsible = (openState || childLinks.includes(location.pathname)) && !onlyIcon;

  return (
    <>
      {onlyIcon ? (
        <IconButton
          onClick={() => {
            setDrawerWidth(drawerSizes.expanded);
            setOpenState(true);
          }}
          sx={{
            color: theme.palette.grey[700],
            backgroundColor: 'transparent',
            minWidth: '2.65rem',
            width: '2.65rem',
            height: '2.65rem',
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
        >
          <Icon />
        </IconButton>
      ) : (
        <Button
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            textTransform: 'none',
            fontWeight: '400',
            lineHeight: '150%',
            letterSpacing: '0.00938rem',
            color: theme.palette.grey[700],
            backgroundColor: 'transparent',
            padding: '.65rem .65rem .65rem 1.375rem',
            boxShadow: 'none',
            height: '2.65rem',
            '&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: `${theme.palette.grey[200]}`,
              boxShadow: 'none',
            },
          }}
        >
          <Icon
            sx={{
              marginRight: '1.5rem',
            }}
          />

          <Typography
            variant='body1'
            sx={{
              marginRight: 'auto',
            }}
          >
            {title}
          </Typography>
          {openCollapsible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      )}
      <Collapse in={openCollapsible} timeout='auto' unmountOnExit>
        <List component='div' disablePadding sx={{ pl: 4, display: 'flex', flexDirection: 'column', gap: 1 }}>
          {children}
        </List>
      </Collapse>
    </>
  );
};

export { Collapsible };

import { IconButton } from '@mui/material';
import { Box, CloseIcon } from '@the-pistol/zitcha-component-library';
import React from 'react';
import { Asset, useDeleteAssetsAssetId } from 'v2/lib/api/ad-management';

export const FilePreviews = ({ files, deleteFile }: { files: Array<Asset>; deleteFile: (item: Asset) => void }) => {
  const { mutateAsync, isPending } = useDeleteAssetsAssetId();

  return (
    <Box
      display='flex'
      flexDirection='row'
      gap={1}
      flexWrap='wrap'
      alignItems='flex-start'
      sx={{
        marginTop: '1rem',
      }}
    >
      {files?.map((item, index) => (
        <Box
          key={index}
          sx={{
            position: 'relative',
          }}
        >
          {item.assetType === 'video' ? (
            <video
              src={item.url}
              controls
              style={{
                aspectRatio: '16/9',
                width: '200px',
                height: 'auto',
                borderRadius: '5px',
              }}
            />
          ) : (
            <img
              src={item.url}
              alt=''
              style={{ borderRadius: '5px', width: '200px', height: '150px', objectFit: 'cover', margin: 0 }}
            />
          )}

          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              backgroundColor: 'rgba(0,0,0,0.5)',
              borderRadius: '50%',
            }}
          >
            <IconButton
              disabled={isPending}
              onClick={async () => {
                if (!item.id) return;
                await mutateAsync({ assetId: item.id });
                deleteFile(item);
              }}
              color='primary'
              sx={{
                backgroundColor: 'rgba(255,255,255,0.5)',
              }}
              size='small'
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

import React from 'react';
import { useGetCalendarPeriods } from '../../lib/api/ad-management';
import { useSelector } from 'react-redux';
import { selectAdTypes } from '../../../features/session/sessionSlice';
import { PlacementList } from '../../components/PlacementList/PlacementList';
import {
  GridRenderCellParams,
  TextField,
  Box,
  InputAdornment,
} from '@the-pistol/zitcha-component-library';
import { Location } from '../../lib/api/ad-management';
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material'; //todo export AutocompleteRenderInputParams and isOptionEqualToValue from zitcha-component-library
import Popover from '@mui/material/Popover'; //todo export Popover from zitcha-component-library
import { formatDisplayDate } from 'helpers/DateHelpers';
import { RenderCurrency } from 'v2/components/RenderCurrency/RenderCurrency';
import { useSafeGetBundle } from 'v2/lib/hooks/useGetBundle';

type IdSetter = (
  id: React.SetStateAction<{
    ad_type_id: number | undefined;
    calendar_period_id: string | undefined;
    location_id: string | undefined;
  }>
) => void;

const Flexer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      display='flex'
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        padding: '8px',
      }}
    >
      {children}
    </Box>
  );
};
export const ScheduleCell = ({
  apiRef,
  organisationId,
  setIds,
  readOnly = false,
  ...props
}: {
  apiRef: React.MutableRefObject<any>;
  organisationId: string;
  readOnly?: boolean;
  setIds?: IdSetter;
} & GridRenderCellParams) => {
  const { data: calendarPeriodsData, isLoading: calendarPeriodsIsLoading } = useGetCalendarPeriods({ organisation_id: organisationId });
  const { id, value, field } = props;

  const handleValueChange = (newValue: unknown) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  const rowMediaTypeId = props.row?.mediaType?.id;
  const rowPlacementId = props.row?.placement?.id;

  return (
    <Flexer>
      <Autocomplete
        size='medium'
        fullWidth
        getOptionLabel={(data) =>
          `${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`
        }
        renderOption={(props, data) => (
          <li {...props} key={data.id}>
            {`${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} variant='standard' />}
        value={value ?? null}
        options={calendarPeriodsData?.data ?? []}
        loading={calendarPeriodsIsLoading}
        onChange={(_, newValue) => {
          setIds?.(() => ({ calendar_period_id: newValue?.id, ad_type_id: rowMediaTypeId, location_id: rowPlacementId }));
          handleValueChange(newValue);
        }}
        readOnly={readOnly}
      />
    </Flexer>
  );
};

// media type
export const MediaTypeCell = ({
  apiRef,
  organisationId,
  setIds,
  readOnly = false,
  ...props
}: {
  apiRef: React.MutableRefObject<any>;
  organisationId: string;
  readOnly?: boolean;
  setIds?: IdSetter;
} & GridRenderCellParams) => {
  const { id, value, field } = props;
  const adTypes = useSelector(selectAdTypes);

  const handleValueChange = (newValue: unknown) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  const rowScheduleId = props.row?.schedule?.id;
  const rowPlacementId = props.row?.placement?.id;

  return (
    <Flexer>
      <Autocomplete
        fullWidth
        getOptionLabel={(data) => data?.label}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} variant='standard' />}
        value={value ?? null}
        options={adTypes ?? []}
        onChange={(_, newValue) => {
          setIds?.(() => ({ ad_type_id: newValue?.id, calendar_period_id: rowScheduleId, location_id: rowPlacementId }));
          handleValueChange(newValue);
        }}
        readOnly={readOnly}
      />
    </Flexer>
  );
};

// placement
export const PlacementCell = ({
  apiRef,
  organisationId,
  setIds,
  ...props
}: {
  apiRef: React.MutableRefObject<any>;
  organisationId: string;
  setIds?: IdSetter;
} & GridRenderCellParams) => {
  const { id, value, field } = props;

  const rowScheduleId = props.row?.schedule?.id;
  const rowMediaTypeId = props.row?.mediaType?.id;

  const handleValueChange = (newValue: Location) => apiRef.current.setEditCellValue({ id, field, value: newValue });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: any) => setAnchorEl(event.target);
  const handleClose = () => setAnchorEl(null);
  const handleChange = (location: Location | undefined) => {
    if (location) {
      setIds?.(() => ({ location_id: location.id, ad_type_id: rowMediaTypeId, calendar_period_id: rowScheduleId }));
      handleValueChange(location);
    }
    handleClose();
  };

  const textValue = value ? `${value?.type} : ${value?.name}` : '';

  return (
    <>
      <Flexer>
        <TextField
          id='placement'
          value={textValue}
          fullWidth
          variant='standard'
          onClick={handleClick}
          autoComplete='off'
        />
      </Flexer>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PlacementList retailerId={organisationId} onSelectPlacement={handleChange} />
      </Popover>
    </>
  );
};

// Rate
export const RateCell = (props: GridRenderCellParams) => {
  const { data: bundleData } = useSafeGetBundle(props?.row?.bundleIds?.[0]);
  const rate = bundleData?.data.price || '-';
  return (
    <Flexer>
      <RenderCurrency amount={rate} />
    </Flexer>
  );
};

// Budget
export const BudgetCell = (props: GridRenderCellParams<any, number>) => {
  const { data: bundleData } = useSafeGetBundle(props?.row?.bundleIds?.[0]);
  const rate = bundleData?.data.price;
  const discount = bundleData?.data?.discount || 0;
  const budget = rate ? rate * (1 - discount / 100) : 0;
  return (
    <Flexer>
      <RenderCurrency amount={budget} />
    </Flexer>
  );
};

// discount

export const DiscountCell = (props: GridRenderCellParams) => {
  const { data: bundleData } = useSafeGetBundle(props?.row?.bundleIds?.[0]);
  const discount = bundleData?.data?.discount || 0;
  return (
    <Flexer>
      <TextField
        fullWidth
        variant='standard'
        InputProps={{
          startAdornment: <InputAdornment position='start'>%</InputAdornment>,
        }}
        value={discount}
        disabled
      />
    </Flexer>
  );
};

// sku codes
export const SkuCodesCell = ({
  apiRef,
  ...props
}: GridRenderCellParams<any, string> & {
  apiRef: React.MutableRefObject<any>;
}) => {
  const { id, value, field } = props;

  const handleValueChange = (newValue: string) => apiRef.current.setEditCellValue({ id, field, value: newValue });

  return (
    <Flexer>
      <TextField
        fullWidth
        variant='standard'
        value={value}
        onChange={(event) => handleValueChange(event.target.value)}
      />
    </Flexer>
  );
};

import React, { useState } from 'react';
import { Button } from '@the-pistol/zitcha-component-library';
import { NewPlanModal } from 'v2/components/NewPlanModal/NewPlanModal';

export const NewPlanModalButton = () => {
  const [isNewPlanModalOpen, setIsNewPlanModalOpen] = useState(false);

  return (
    <div>
      <Button
        variant='contained'
        color='primary'
        size='large'
        onClick={() => setIsNewPlanModalOpen(true)}
        aria-label='Create new plan'
        aria-expanded={isNewPlanModalOpen}
      >
        New Plan +
      </Button>
      <NewPlanModal isOpen={isNewPlanModalOpen} onClose={() => setIsNewPlanModalOpen(false)} />
    </div>
  );
};

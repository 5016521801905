import { Box, Divider, Grid, TextField, Typography } from '@the-pistol/zitcha-component-library';
import React, { useEffect, useState } from 'react';
import { Ad, Asset, usePostAssets } from 'v2/lib/api/ad-management';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UploadIcon from '@mui/icons-material/Upload';
import { Controller, useForm } from 'react-hook-form';
import { DragAndDropUploader } from './Uploader/DradAndDropUploader';

interface AssetsTabContent {
  id?: string;
  name?: string;
  fields: { link: string; header_text: string; assets: Array<Asset['id']> };
}
export const AdsTab = ({
  ads,
  handleContentChange,
}: {
  ads: Array<Ad>;
  handleContentChange: (content: AssetsTabContent) => void;
}) => {
  return (
    <Box
      sx={{
        marginTop: '1rem',
      }}
    >
      {ads.map((ad: Ad, index: number) => (
        <AdAssetAccordion key={ad.id} ad={ad} handleContentChange={handleContentChange} index={index} />
      ))}
    </Box>
  );
};

const AdAssetAccordion = ({
  index,
  ad,
  handleContentChange,
}: {
  index: number;
  ad: Ad;
  handleContentChange: (content: AssetsTabContent) => void;
}) => {
  const [assets, setAssets] = useState<Array<Asset>>(ad?.fields?.[index]?.assets || []);

  const { name } = ad;

  const { mutateAsync, isPending } = usePostAssets();

  const { control, getValues, watch } = useForm({
    defaultValues: {
      headerText: ad?.fields?.[index]?.header_text || '',
      link: ad?.fields?.[index]?.link || '',
    },
    mode: 'onBlur',
  });

  useEffect(() => {
    handleContentChange({
      id: ad?.id,
      name: ad?.name || '',
      fields: {
        link: getValues('link') || '',
        header_text: getValues('headerText') || '',
        assets: assets.map((asset) => asset.id),
      },
    });
  }, [assets, watch('link'), watch('headerText')]);

  return (
    <Accordion
      disableGutters
      sx={{
        boxShadow: 'none',
        borderTop: 'solid rgba(0, 0, 0, 0.12)',
        borderTopWidth: 'thin',
        borderRadius: '0px !important',
      }}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />} aria-controls='panel1-content' id='panel1-header'>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', with: '100%' }}>
          <Typography variant='subtitle1'>{`${name} ${!name ? `Ad ${index + 1}` : ''}`}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', with: '100%' }}>
            <Typography variant='body2'>large image</Typography>
            <Typography variant='body2'>1320 (w) x 346 (h)</Typography>
            <UploadIcon sx={{ color: 'GrayText' }} />
          </Box>
        </Box>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Box sx={{ flexGrow: 1, marginTop: '.5em' }}>
          <Grid container spacing={2}>
            <Grid xs={12} display='flex' flexDirection='row' gap={2}>
              <Grid xs={4}>
                <AdAssetLabel label='Header text' />
              </Grid>
              <Grid xs={4}>
                <AdAssetLabel label='Link' />
              </Grid>
            </Grid>
            <Grid
              xs={12}
              display='flex'
              flexDirection='row'
              gap={2}
              sx={{
                padding: '1rem',
                border: 'none',
                borderTop: 'solid rgba(0, 0, 0, 0.12)',
                borderBottom: 'solid rgba(0, 0, 0, 0.12)',
                borderWidth: 'thin',
                backgroundColor: '#FBFBFB',
              }}
            >
              <Grid xs={4}>
                <Controller
                  name='headerText'
                  control={control}
                  rules={{ required: 'Header text is required' }}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <TextField
                      helperText={error ? error.message : null}
                      error={!!error}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      fullWidth
                      variant='standard'
                    />
                  )}
                />
              </Grid>
              <Grid xs={4}>
                <Controller
                  name='link'
                  control={control}
                  rules={{ required: 'Link is required', pattern: { value: /https?:\/\/.*/, message: 'Invalid URL' } }}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <TextField
                      helperText={error ? error.message : null}
                      error={!!error}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      fullWidth
                      variant='standard'
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            padding: '2rem 1rem',
          }}
        >
          <DragAndDropUploader
            ad={ad}
            files={assets}
            setAssets={setAssets}
            uploading={isPending}
            onFileChange={(files) => {
              mutateAsync({ data: { file: files.at(-1) } }).then((res) => {
                setAssets([...assets, res?.data]);
              });
            }}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
const AdAssetLabel = ({ label }: { label: string }) => (
  <Typography
    variant='caption'
    sx={{
      padding: '1rem',
      display: 'flex',
      fontWeight: 600,
    }}
  >
    {label}
  </Typography>
);

import React from 'react';
import {
  IconButton,
  Chip,
  GridRenderCellParams,
  EditIcon,
  VisibilityIcon,
  Typography,
  Stack,
  SearchIcon,
  Switch,
  Box,
} from '@the-pistol/zitcha-component-library';
import { formatRecentDate, formatDisplayDate } from 'helpers/DateHelpers';
import { PlanStatus } from 'v2/lib/api/ad-management';
import { statusNameMapping, statusChipVariantMap, statusChipColorMap } from 'v2/utils/planStatusUtils';
import { useNavigate } from 'react-router-dom';
import { ViewMode } from './PlanPage/ViewModeType';

const actionVariants: { [key: string]: 'filled' | 'outlined' } = {
  clash: 'outlined',
  pending_approval: 'filled',
  'need assets': 'outlined',
  'deadlines soon': 'outlined',
  'need more info': 'outlined',
};

const actionColors: { [key: string]: 'default' | 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' } =
  {
    clash: 'error',
    rejected: 'error',
    pending_approval: 'primary',
    'need assets': 'info',
    'deadlines soon': 'warning',
    reserved: 'warning',
    'need more info': 'secondary',
    live: 'success',
  };

export const renderActionButtons = (params: GridRenderCellParams, navigate: ReturnType<typeof useNavigate>) => {
  const planId = params.id;
  return (
    <>
      <IconButton
        color='primary'
        ariaLabel={'Edit plan button'}
        onClick={() => {
          navigate(`/plans-ad-sets/${planId}`, { state: { viewMode: ViewMode.EDITING } });
        }}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        color='primary'
        ariaLabel={'View plan button'}
        onClick={() => {
          navigate(`/plans-ad-sets/${planId}`, { state: { viewMode: ViewMode.REVIEWING } });
        }}
      >
        <VisibilityIcon />
      </IconButton>
    </>
  );
};

export const renderStatusChip = (params: GridRenderCellParams) => {
  const status = params.value as PlanStatus;
  const statusName = statusNameMapping[status];
  const variant = statusChipVariantMap[status];
  const color = statusChipColorMap[status];
  return <Chip label={statusName} variant={variant} color={color} />;
};

export const renderLastModified = (params: GridRenderCellParams) => {
  const date = params.value as Date;
  return formatRecentDate(date);
};

export const renderWalletDateRange = (params: GridRenderCellParams) => {
  const dateRange = params.value as string;
  const [startAt, endAt] = dateRange.split(' - ');
  return `${formatDisplayDate(new Date(startAt))} - ${formatDisplayDate(new Date(endAt))}`;
};

//very rough implementation of the 'hidden chips' feature
export const renderAdSetActions = (params: GridRenderCellParams) => {
  //todo get from BE
  type AdSetAction = {
    count: number;
    label: string;
  };
  const actions: Array<AdSetAction> = [];

  // Get the width of the column, default to 400 if undefined
  const columnWidth = params.colDef.width || 400;

  // Calculate the width of each chip based on the length of the label and count
  const chipWidths = actions.map((action) => (action.count.toString().length + action.label.length) * 8 + 6);

  // Calculate the maximum number of Chips that can be displayed
  let totalWidth = 0;
  let maxChips = 0;
  for (const width of chipWidths) {
    if (totalWidth + width > columnWidth) break;
    totalWidth += width;
    maxChips++;
  }

  // Split the actions into those that will be displayed and those that won't
  const displayedActions = actions.slice(0, maxChips);
  const hiddenActions = actions.slice(maxChips);

  // Calculate the total count of the hidden actions
  const hiddenCount = hiddenActions.reduce((total, action) => total + action.count, 0);

  return (
    <>
      {displayedActions.map((action, index) => (
        <Chip
          sx={{ cursor: 'pointer', marginRight: '4px' }}
          key={index}
          label={`${action.count} ${action.label}`}
          variant={actionVariants[action.label] || 'filled'}
          color={actionColors[action.label] || 'default'}
          onClick={() => {
            //eslint-disable-next-line no-console
            console.log('TODO: take me to action on plan: ', plan);
          }}
        />
      ))}
      {hiddenActions.length > 0 && (
        <Chip
          sx={{ cursor: 'pointer' }}
          label={`+${hiddenCount}`}
          variant='outlined'
          color='default'
          onClick={() => {
            //eslint-disable-next-line no-console
            console.log('TODO: take me to plan: ', plan);
          }}
        />
      )}
    </>
  );
};

// AdSetsTable cell renders for the DataGrid

export const renderSKUCode = ({
  params,
  onClick,
}: {
  params: GridRenderCellParams;
  onClick: (skuCode: Array<string>) => void;
}) => {
  return (
    <Stack spacing={1} direction='row' alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
      <Typography variant='caption'>
        {`${params?.value[0] ? params.value[0] : ''} ${params.value.length > 1 ? `+${params?.value?.length - 1}` : ''}`}
      </Typography>
      <IconButton
        color='primary'
        size='small'
        ariaLabel={'View SKU codes'}
        onClick={() => {
          onClick(params.value);
        }}
      >
        <SearchIcon />
      </IconButton>
    </Stack>
  );
};

export const renderRetailer = (params: GridRenderCellParams) => {
  return <Typography variant='caption'>{params.value}</Typography>;
};

export const renderSwitch = (
  params: GridRenderCellParams,
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, params: GridRenderCellParams) => void
) => {
  return (
    <Switch
      value={params.value}
      checked={params.value === 'live'}
      onChange={(event, checked) => onChange(event, checked, params)}
    />
  );
};
export const renderAdSetsStatusChip = (params: GridRenderCellParams) => {
  return (
    <Box
      gap={1}
      display='flex'
      sx={{
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Chip label={params.value.adSetStatus} color={actionColors[params.value.adSetStatus] || 'default'} />
      <Chip
        label={params.value.planStatus}
        variant='outlined'
        color={
          actionColors[params.value.planStatus as PlanStatus] ||
          (params.value.planStatus === (PlanStatus.proposed || PlanStatus.agreed) ? 'warning' : 'default')
        }
      />
    </Box>
  );
};

export const renderAdSetsActions = ({
  isLive,
  onClickEdit,
  onClickView,
}: {
  isLive: boolean;
  onClickEdit: () => void;
  onClickView: () => void;
}) => (
  <>
    {isLive ? (
      <IconButton
        color='primary'
        ariaLabel={'View plan button'}
        onClick={() => {
          onClickView();
        }}
      >
        <VisibilityIcon />
      </IconButton>
    ) : (
      <IconButton
        color='primary'
        ariaLabel={'Edit plan button'}
        onClick={() => {
          onClickEdit();
        }}
      >
        <EditIcon />
      </IconButton>
    )}
  </>
);

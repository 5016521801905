import React from 'react';
import { Paper, Typography, ArrowUpwardIcon, ArrowDownwardIcon } from '@the-pistol/zitcha-component-library';

interface MetricsCardProps {
  heading: string;
  subText: string;
  percent: number;
}

export const MetricsCard: React.FC<MetricsCardProps> = ({ heading, subText, percent }) => {
  const isPositive = percent >= 0;

  return (
    <Paper
      style={{
        backgroundColor: '#FBFBFB',
        minWidth: 360,
        height: 100,
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <Typography variant='h6'>{heading}</Typography>
        <Typography variant='body2'>{subText}</Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='h4'>{Math.abs(percent)}%</Typography>
        {isPositive ? (
          <ArrowUpwardIcon fontSize='large' color='success' data-testid='arrow-upward-icon' />
        ) : (
          <ArrowDownwardIcon fontSize='large' color='error' data-testid='arrow-downward-icon' />
        )}
      </div>
    </Paper>
  );
};

import React, { SyntheticEvent, useState } from 'react';
import { Box, Button, Modal, Tab, Tabs, Typography } from '@the-pistol/zitcha-component-library';
import CheckIcon from '@mui/icons-material/Check';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Ad, AdSet, AdType, Location, CalendarPeriod, UpdateAdSetBody, UpdateAdsInAdSetBody, useUpdateAdsInAdSet } from 'v2/lib/api/ad-management';
import { useAdSetActions } from 'v2/lib/hooks/useAdSetActions';
import { AdsTab } from 'v2/features/plansAndAdSets/AdsTab';
import DetailsTable from './DetailsTable';

const saveDetailsToBackend = (
  existingAdSet: AdSet,
  newBundleId: string | null,
  updatedAds: Array<Ad>,
  updateAdSet: (params: { id: string; data: UpdateAdSetBody }) => Promise<void>,
  mutateAdsInAdSet: (params: { id: string; data: UpdateAdsInAdSetBody }) => Promise<void>,
) => {
  const promises = [];

  if (newBundleId && existingAdSet?.bundleIds?.[0] !== newBundleId)
    promises.push(
      updateAdSet({
        id: existingAdSet.id,
        data: {
          existing_bundle_id: existingAdSet?.bundleIds?.[0],
          new_bundle_id: newBundleId,
        },
      })
    );

  if (updatedAds.length > 0) {
    promises.push(
      mutateAdsInAdSet({
        id: existingAdSet?.id,
        data: {
          ads: updatedAds.map((ad) => ({
            id: ad.id ?? undefined,
            name: ad.name || '',
            fields: [
              {
                header_text: ad?.fields?.header_text,
                link: ad?.fields?.link,
                assets: ad?.fields?.assets,
              },
            ],
          })),
        },
      })
    );
  }

  return Promise.all(promises);
};

const saveErrorHandler = (error: Error): void => {
  //eslint-disable-next-line no-console
  console.error('Save Error handling not implemented: ' + error);
};

interface AdSetModalProps {
  isOpen: boolean;
  isReadOnly: boolean;
  adSet: AdSet;
  onClose: () => void;
  saveHandler?: AdSetModalSaveHandler;
}

export const AdSetModal = ({ isOpen, isReadOnly, adSet: existingAdSet, onClose, saveHandler }: AdSetModalProps) => {
  const [newBundleId, setNewBundleId] = useState<string | null>(null);
  const [selectedAdSetsTab, setSelectedAdSetsTab] = useState<AdSetsTabValue>('details');
  const [selectedSchedule, setSelectedSchedule] = useState<CalendarPeriod | null>(null);
  const [selectedMediaType, setSelectedMediaType] = useState<AdType | null>(null);
  const [selectedPlacement, setSelectedPlacement] = useState<Location | null>(null);
  const [monetaryValues, setMonetaryValues] = useState<{
    rate: number;
    discount: number;
    budget: number;
  }>({
    rate: 0,
    discount: 0,
    budget: 0,
  });

  const resetModalStates = () => {
    setSelectedAdSetsTab('details');
    setSelectedSchedule(null);
    setSelectedMediaType(null);
    setSelectedPlacement(null);
    setNewBundleId(null);
    setAdsContent([]);
  };

  const { updateAdSet } = useAdSetActions();
  const { mutateAsync: mutateAdsInAdSet } = useUpdateAdsInAdSet();
  const existingAds = existingAdSet.ads;

  const defaultAd = [
    {
      id: '',
      name: '',
      fields: [
        {
          header_text: '',
          link: '',
          assets: [],
        },
      ],
    },
  ];

  const initaialAdsContent = existingAds?.length ? existingAds : defaultAd;

  const [adsContent, setAdsContent] = useState<Array<Ad>>(existingAds?.length ? existingAds : defaultAd);

  const handleTabChange = (_: SyntheticEvent, newValue: AdSetsTabValue) => {
    setSelectedAdSetsTab(newValue);
  };


  const handleSave = () => {
    if (saveHandler) {
      saveHandler(existingAdSet, adsContent, selectedSchedule, selectedMediaType, selectedPlacement, newBundleId)
        .catch((e: Error) => {
          saveErrorHandler(e)
        })
        .then(() => onClose());
    } else {
      saveDetailsToBackend(existingAdSet, newBundleId, adsContent, updateAdSet, mutateAdsInAdSet)
        .catch((e: Error) => {
          saveErrorHandler(e)
        })
        .then(() => onClose());
    }
  }

  const handleClose = () => {
    resetModalStates();
    onClose();
  }

  return (
    <Modal open={isOpen} onClose={handleClose} size='large'>
      <>
        <Typography variant='h5'>{isReadOnly ? 'Ad set details' : 'Edit ad set'}</Typography>
        {!isReadOnly &&
          <div className='d-flex justify-content-between'>
            <Typography variant='body1' data-testid='pageHeading' mt={2} mb={1}>
              Add information specific to the media type.
            </Typography>
          </div>
        }
        <Tabs value={selectedAdSetsTab} onChange={handleTabChange}>
          <Tab color='error' icon={<CheckIcon />} iconPosition={'start'} label='Details' value={'details'} />
          <Tab icon={<MoreHorizIcon />} iconPosition={'start'} label={`Ads`} value={'ads'} />
        </Tabs>
        <div style={{ display: selectedAdSetsTab === 'details' ? 'block' : 'none' }}>
          <DetailsTable
            data={existingAdSet}
            selectedMediaType={selectedMediaType}
            setSelectedMediaType={setSelectedMediaType}
            selectedPlacement={selectedPlacement}
            setSelectedPlacement={setSelectedPlacement}
            selectedSchedule={selectedSchedule}
            setSelectedSchedule={setSelectedSchedule}
            setSelectedBundleId={setNewBundleId}
            monetaryValues={monetaryValues}
            setMonetaryValues={setMonetaryValues}
          />
        </div>
        <div style={{ display: selectedAdSetsTab === 'ads' ? 'block' : 'none' }}>
          <AdsTab
            ads={initaialAdsContent}
            handleContentChange={(cont: Ad) => {
              setAdsContent((prev: Array<Ad>) => {
                const index = prev.findIndex((ad: Ad) => ad.id === cont.id);
                if (index === -1) {
                  return [...prev, cont];
                }
                return prev.map((ad: Ad, i: number) => (i === index ? cont : ad));
              });
            }}
          />
        </div>
        <Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
          <Button
            variant='text'
            color='error'
            aria-label='Close without saving'
            className='w-5/12 mr-3'
            onClick={onClose}
          >
            CLOSE WITHOUT SAVING
          </Button>
          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={handleSave}
            aria-label='Save ad set'
            disabled={!newBundleId || isReadOnly}
          >
            SAVE AD SET
          </Button>
        </Box>
      </>
    </Modal>
  );
};

type AdSetsTabValue = 'details' | 'ads';

export type AdSetModalSaveHandler = (
  existingAdSet: AdSet,
  updatedAds: Array<Ad>,
  selectedSchedule: CalendarPeriod | null,
  selectedMediaType: AdType | null,
  selectedPlacement: Location | null,
  newBundleId: string | null,
) => Promise<void>;
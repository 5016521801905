import React, { useEffect, useMemo, useState } from 'react';
import { DateRangePicker, Box, type DateRange, Autocomplete, TextField } from '@the-pistol/zitcha-component-library';
import { AdSetSearch, AdSetStatusEnum } from 'v2/lib/api/ad-management';
import { CustomSelect } from './CustomSelect';
import { useAdTypes } from 'v2/lib/hooks/useAdTypes';
import { useBrands } from 'v2/lib/hooks/useBrands';
import { useBundleLocations } from 'v2/lib/hooks/useBundleLocations';

type AdSetsTableFiltersProps = {
  onFilterChange: (newFilter: Partial<AdSetSearch>) => void;
};
type FilterOption<T> = { id: T; selected: boolean; name: string };

export const AdSetsTableFilters: React.FC<AdSetsTableFiltersProps> = ({ onFilterChange }) => {
  const mediaTypes = useAdTypes();
  const brands = useBrands();
  const { placementOptions: placements, isLoading: isLoadingPlacements } = useBundleLocations();
  const [activeToggleFilter, setActiveToggleFilter] = useState<AdSetStatusEnum | 'Active & Inactive'>('Active & Inactive');
  const allStatuses: Array<AdSetStatusEnum> = Object.keys(AdSetStatusEnum) as Array<AdSetStatusEnum>;
  const [statusOptions, setStatusOptions] = useState<Array<FilterOption<string>>>(
    allStatuses.map((status) => ({ id: status, selected: false, name: status }))
  );
  const [brandOptions, setBrandOptions] = useState<Array<FilterOption<string>>>(
    brands.map((brand) => ({ id: brand.id, selected: false, name: brand.name }))
  );

  const [mediaTypeOptions, setMediaTypeOptions] = useState<Array<FilterOption<string>>>(
    mediaTypes.map((mediaType) => ({ id: mediaType.id, selected: false, name: mediaType.name }))
  );

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | undefined>({ from: undefined, to: undefined });
  const [placementOptions, setPlacementOptions] = useState<Array<FilterOption<string>>>(() => placements);
  //compute selected options from options
  const selectedStatusOptions = useMemo(
    () => statusOptions?.filter((option: FilterOption<string>) => option.selected),
    [statusOptions]
  );
  const selectedBrandOptions = useMemo(
    () => brandOptions?.filter((option: FilterOption<string>) => option.selected),
    [brandOptions]
  );
  const selectedMediaTypeOptions = useMemo(
    () => mediaTypeOptions?.filter((option: FilterOption<string>) => option.selected),
    [mediaTypeOptions]
  );

  const selectedPlacementOptions = useMemo(
    () => placementOptions?.filter((option: FilterOption<string>) => option.selected),
    [placementOptions]
  );
  useEffect(() => {
    if (brands) {
      setBrandOptions(brands.map((brand) => ({ id: brand.id, selected: false, name: brand.name })));
    }
  }, []);
  useEffect(() => {
    if (!isLoadingPlacements) {
      setPlacementOptions(placements);
    }
  }, [isLoadingPlacements]);


  const calculateActiveToggleFilterValue = (activeToggleFilter: AdSetStatusEnum | string | undefined) => {
    switch (activeToggleFilter) {
      case AdSetStatusEnum.live:
        return [AdSetStatusEnum.live];
      case AdSetStatusEnum.paused:
        return [AdSetStatusEnum.paused];
      default:
        return [];
    }
  }

  const calculateStatusFilter = () => {
    return [
      ...selectedStatusOptions.map((option: FilterOption<AdSetStatusEnum>) => option.id),
      ...calculateActiveToggleFilterValue(activeToggleFilter),
    ]
  }

  useEffect(() => {
    onFilterChange({
      statuses:
        calculateStatusFilter().length ? calculateStatusFilter() : undefined,
      ad_type_ids:
        selectedMediaTypeOptions && selectedMediaTypeOptions.length > 0
          ? selectedMediaTypeOptions.map((option) => Number(option.id))
          : undefined,
      supplier_ids:
        selectedBrandOptions && selectedBrandOptions.length > 0
          ? selectedBrandOptions.map((option: FilterOption<string>) => option.id)
          : undefined,
      start_at: selectedDateRange?.to ? selectedDateRange?.from?.toISOString() : undefined,
      end_at: selectedDateRange?.to?.toISOString(),
    });
  }, [selectedBrandOptions, selectedMediaTypeOptions, selectedDateRange, selectedStatusOptions, activeToggleFilter]);

  return (
    <Box sx={{ display: 'flex', gap: 2, padding: 2 }} className='twd-overflow-auto'>
      <CustomSelect
        id='active-toggle'
        label='Active toggle'
        options={[
          { value: 'Active & Inactive', label: 'Active & Inactive' },
          { value: AdSetStatusEnum.live, label: 'Active' },
          { value: AdSetStatusEnum.paused, label: 'Inactive' },
        ]}
        selectedValue={activeToggleFilter}
        onChange={setActiveToggleFilter}
      />

      <Autocomplete
        id='status'
        label='Status'
        options={statusOptions}
        value={selectedStatusOptions}
        onChange={(_, newValue: Array<FilterOption<string>>) => {
          setStatusOptions(
            statusOptions?.map((option) => ({
              ...option,
              selected:
                newValue?.some((selectedOption: FilterOption<string>) => selectedOption.id === option.id) || false,
            }))
          );
        }}
        sx={{ minWidth: 200 }}
        multiple
        getOptionLabel={(option: FilterOption<string>) => option.name}
        renderInput={(params) => <TextField {...params} label='Status' />}
      />
      <Autocomplete
        id='brand'
        value={selectedBrandOptions}
        options={brandOptions}
        onChange={(_, newValue: Array<FilterOption<string>>) => {
          setBrandOptions(
            brandOptions?.map((option) => ({
              ...option,
              selected:
                newValue?.some((selectedOption: FilterOption<string>) => selectedOption.id === option.id) || false,
            }))
          );
        }}
        sx={{ minWidth: 200 }}
        multiple
        getOptionLabel={(option: FilterOption<string>) => option.name}
        renderInput={(params) => <TextField {...params} label='Brands' />}
      />

      <Autocomplete
        id='media-type'
        value={selectedMediaTypeOptions}
        options={mediaTypeOptions}
        onChange={(_, newValue: Array<FilterOption<string>>) => {
          setMediaTypeOptions(
            mediaTypeOptions?.map((option) => ({
              ...option,
              selected:
                newValue?.some((selectedOption: FilterOption<string>) => selectedOption.id == option.id) || false,
            }))
          );
        }}
        sx={{ minWidth: 200 }}
        multiple
        getOptionLabel={(option: FilterOption<string>) => option.name}
        renderInput={(params) => <TextField {...params} label='Media types' />}
      />

      <DateRangePicker
        placeholderText='Schedule'
        date={selectedDateRange}
        setDate={(date) => setSelectedDateRange(date)}
      />
      <Autocomplete
        id='placements'
        label='Placements'
        options={placementOptions}
        value={selectedPlacementOptions}
        onChange={(_, newValue: Array<FilterOption<string>>) => {
          setPlacementOptions(
            placementOptions?.map((option) => ({
              ...option,
              selected:
                newValue?.some((selectedOption: FilterOption<string>) => selectedOption.id == option.id) || false,
            }))
          );
        }}
        sx={{ minWidth: 200 }}
        multiple
        getOptionLabel={(option: FilterOption<string>) => option.name}
      />
    </Box>
  );
};

import React from 'react';
import { Modal, Button, Box, Typography } from '@the-pistol/zitcha-component-library';

interface AreYouSureModalProps {
  isOpen: boolean;
  onClose: () => void;
  onYes: (arg0: unknown) => void;
  onNo: () => void;
  title?: string;
  message?: string;
  content?: React.ReactNode;
}

export const AreYouSureModal = ({
  isOpen,
  onClose,
  onYes,
  onNo,
  title = 'Are you sure?',
  message,
  content,
}: AreYouSureModalProps) => {
  return (
    <Modal open={isOpen} size='small' title={title} onClose={onClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        {message && <Typography variant='body1'>{message}</Typography>}
        {content}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2 }}>
          <Button variant='outlined' color='success' onClick={onYes} data-testid='yes-button'>
            Yes
          </Button>
          <Button color='error' onClick={onNo} data-testid='no-button'>
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

import React, { useMemo, useState } from 'react';
import {
  Box,
  GridColDef,
  GridPaginationModel,
  Typography,
  DataGrid,
  GridRowSelectionModel,
  GridRowModel,
} from '@the-pistol/zitcha-component-library';
import { usePlanAdSets } from 'v2/lib/hooks/usePlanAdSets';
import { AdSet, AdSetStatusEnum, AdType, GetPlanAdSetsParams, PerPageEnum } from 'v2/lib/api/ad-management/model';
import { renderAdSetActions, renderActions, RenderSchedule } from './ViewPlanAdSetsTableCellRenderers';
import { debounce } from 'debounce';
import { PlanAdSetsTableFilters } from '../PlanAdSetsTableFilters';
import { useAdSetActions } from 'v2/lib/hooks/useAdSetActions';
import { PlanViewModals } from '../PlanViewModals';
import { ViewMode } from '../ViewModeType';
import { BudgetCell, DiscountCell, RateCell } from '../../NewPlanAdSetsCellRenders';

export const ViewPlanAdSetsTable = ({ planId, viewMode }: { planId: string; viewMode: ViewMode }) => {
  // view ad set modal state
  const [viewAdSet, setViewAdSet] = useState<AdSet | null>(null);

  // view ad set modal state
  const [editAdSet, setEditAdSet] = useState<AdSet | null>(null);

  // single approve/reject modals state
  const [approveAdSetId, setApproveAdSetId] = useState<string | null>(null);
  const [rejectAdSetId, setRejectAdSetId] = useState<string | null>(null);

  // bulk action state
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [selectedAdSets, setSelectedAdSets] = useState<Array<AdSet>>([]);

  const {
    planAdSetsData,
    planAdSetsPagination,
    changePlanAdSetsPagination,
    setParams: setPlanAdSetsSearch,
    isLoading,
  } = usePlanAdSets(planId, { per_page: viewMode === ViewMode.EDITING ? PerPageEnum.NUMBER_5 : PerPageEnum.NUMBER_10 });

  const dataGridPaginationModel = {
    pageSize: planAdSetsPagination.perPage ?? PerPageEnum.NUMBER_15,
    page: (planAdSetsPagination.currentPage ?? 1) - 1, //-1 to match 0 vs 1 based indexing
  };

  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changePlanAdSetsPagination(model.page + 1, model.pageSize as PerPageEnum);
  };

  const debouncedSetPlanAdSetsSearch = useMemo(() => debounce(setPlanAdSetsSearch, 300), [setPlanAdSetsSearch]);
  const handleFilterChange = (newFilter: GetPlanAdSetsParams) => {
    debouncedSetPlanAdSetsSearch((prevFilter: GetPlanAdSetsParams) => ({ ...prevFilter, ...newFilter }));
  };

  const { approveAdSet, rejectAdSet } = useAdSetActions(planAdSetsPagination.currentPage, planAdSetsPagination.perPage);

  const columns: Array<GridColDef> = [
    {
      field: 'status',
      headerName: 'Ad set actions',
      renderCell: renderAdSetActions,
      width: 120,
    },
    {
      field: 'Schedule',
      headerName: 'Schedule',
      width: 240,
      renderCell: (params) => <RenderSchedule bundleIds={params.row.bundleIds} />,
    },
    {
      field: 'adType',
      headerName: 'Media type',
      valueGetter: (adType: AdType) => adType?.label,
    },
    { field: 'placement', headerName: 'Placement', width: 300, renderCell: (params) => params.value.join(' > ') },
    { field: 'skuCodes', headerName: 'SKU code(s)' },
    {
      field: 'rate',
      headerName: 'Rate',
      minWidth: 130,
      editable: false,
      renderCell: (params) => <RateCell {...params} />,
    },
    {
      field: 'discount',
      headerName: 'Discount',
      minWidth: 70,
      editable: false,
      renderCell: (params) => <DiscountCell {...params} />,
    },
    {
      field: 'budget',
      headerName: 'Budget',
      minWidth: 130,
      editable: false,
      renderCell: (params) => <BudgetCell {...params} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) =>
        renderActions({
          adSet: params.row.adSet,
          viewAdSet: setViewAdSet,
          editAdSet: setEditAdSet,
          approveAdSet: setApproveAdSetId,
          rejectAdSet: setRejectAdSetId,
          viewMode: viewMode,
        }),
      width: 150,
    },
  ];

  const rows = planAdSetsData?.map((adSet: AdSet) => {
    return {
      id: adSet.id,
      ads: adSet.ads,
      adType: adSet.adType,
      audience: adSet.audience,
      endAt: adSet.endAt,
      name: adSet.name,
      plan: adSet.plan,
      spaces: adSet.spaces,
      startAt: adSet.startAt,
      status: adSet.status,
      placement: adSet.bundleLocationNames,
      skuCodes: adSet?.skus,
      adSet: adSet,
      bundleIds: adSet.bundleIds,
    };
  });

  const handleRowSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    const newSelectedAdSets = rows.filter((row) => row.id && newSelection.includes(row.id));
    setSelectedAdSets(newSelectedAdSets);
    setRowSelectionModel(newSelection);
  };

  const resetRowSelection = () => {
    setRowSelectionModel([]);
    setSelectedAdSets([]);
  };

  const tableTitle =
    viewMode === ViewMode.REVIEWING ? `${planAdSetsPagination.total} ad sets to review` : 'Edit existing ad sets';

  return (
    <>
      <Box mb={1}>
        <Typography variant='h5' color='text.primary'>{tableTitle}</Typography>
      </Box>
      <Box sx={{ backgroundColor: 'white' }} padding={2}>
        {
          false && (
            <PlanAdSetsTableFilters onFilterChange={handleFilterChange} />
          ) /* filters not implemented in back end */
        }
        <DataGrid
          rows={rows ?? []}
          columns={columns}
          loading={isLoading}
          checkboxSelection={viewMode === ViewMode.REVIEWING}
          disableColumnSorting
          disableColumnFilter
          paginationMode='server'
          paginationModel={dataGridPaginationModel}
          rowCount={planAdSetsPagination.total || 0}
          onPaginationModelChange={handlePaginationUpdate}
          pageSizeOptions={[5, 10, 15, 25, 50, 100]}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={handleRowSelectionModelChange}
          isRowSelectable={(params: GridRowModel) => {
            const adSet = params.row.adSet as AdSet;
            const adSetIsApprovedOrRejected =
              adSet.status === AdSetStatusEnum.reserved || adSet.status === AdSetStatusEnum.rejected;
            return !adSetIsApprovedOrRejected;
          }}
        />
      </Box>
      <PlanViewModals
        selectedAdSets={selectedAdSets}
        approveAdSetId={approveAdSetId}
        rejectAdSetId={rejectAdSetId}
        setApproveAdSetId={setApproveAdSetId}
        setRejectAdSetId={setRejectAdSetId}
        approveAdSet={approveAdSet}
        rejectAdSet={rejectAdSet}
        resetRowSelection={resetRowSelection}
        viewAdSet={viewAdSet}
        setViewAdSet={setViewAdSet}
        editAdSet={editAdSet}
        setEditAdSet={setEditAdSet}
        planAdSetsPagination={planAdSetsPagination}
      />
    </>
  );
};

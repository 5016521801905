import React, { useEffect, useState } from 'react';
import {
  DeleteIcon,
  EditIcon,
  Typography,
  Box,
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModes,
  useGridApiRef,
  GridRowParams,
  ContentCopyIcon,
} from '@the-pistol/zitcha-component-library';
import { v4 as uuidv4 } from 'uuid';
import { AdSet, useGetBundles } from 'v2/lib/api/ad-management';
import {
  ScheduleCell,
  MediaTypeCell,
  PlacementCell,
  RateCell,
  BudgetCell,
  DiscountCell,
  SkuCodesCell,
} from './NewPlanAdSetsCellRenders';
import { ViewMode } from './PlanPage/ViewModeType';
import { AdSetModal, AdSetModalSaveHandler } from 'v2/components/AdSetModal/AdSetModal';
import { GridApiCommunity } from '@mui/x-data-grid/internals';

const handleAddClick = (setIds: (ids: Ids) => void, addAdSet: (adSet: AdSet) => void) => {
  const id = uuidv4();

  setIds({ ad_type_id: undefined, calendar_period_id: undefined, location_id: undefined });

  addAdSet({ id });
};

const getAdSetsUpdatedWithNewBundle = (newBundleId: string | undefined, focusedRowId: string, adSets: Array<AdSet>) => {
  return adSets.map((adSet) =>
    adSet.id === focusedRowId
      ? {
          ...adSet,
          bundleIds: newBundleId ? [newBundleId] : [],
        }
      : adSet
  );
};

const startCellEditModeForEditableColumns = (
  adSets: Array<AdSet>,
  columns: Array<GridColDef>,
  apiRef: React.MutableRefObject<GridApiCommunity>
) => {
  if (adSets && columns) {
    adSets.forEach((row) => {
      columns.forEach((column) => {
        if (column.editable && row.id) {
          const mode = apiRef.current.getCellMode(row.id, column.field);
          if (mode !== GridRowModes.Edit) {
            apiRef.current.startCellEditMode({ id: row.id, field: column.field });
          }
        }
      });
    });
  }
};

// NewPlanAdSets component for creating new ad sets in a plan (for new plans use viewMode.NEW, for editing existing plans use viewMode.EDITING)
export const NewPlanAdSets = ({
  organisationId,
  adSets,
  addAdSet,
  removeAdSet,
  updateAdSet,
  setAdSets,
  viewMode,
}: NewPlanAdSetsProps): React.ReactElement => {
  const [editAdSet, setEditAdSet] = useState<AdSet | undefined>(undefined);
  const [focusedRowId, setFocusedRowId] = useState<string | null>(null);
  const [ids, setIds] = useState<Ids>({
    ad_type_id: undefined,
    calendar_period_id: undefined,
    location_id: undefined,
  });
  const apiRef = useGridApiRef();

  const handleSaveAdSet: AdSetModalSaveHandler = async (
    existingAdSet,
    updatedAds,
    selectedSchedule,
    selectedMediaType,
    selectedPlacement,
    newBundleId
  ) => {
    const updatedAdSet = {
      ...existingAdSet,
      bundleIds: newBundleId ? [newBundleId] : existingAdSet.bundleIds,
      ads: updatedAds,
    };
    //update the datagrid component values
    if (existingAdSet.id) {
      apiRef.current.setEditCellValue({ id: existingAdSet.id, field: 'schedule', value: selectedSchedule });
      apiRef.current.setEditCellValue({ id: existingAdSet.id, field: 'mediaType', value: selectedMediaType });
      apiRef.current.setEditCellValue({ id: existingAdSet.id, field: 'placement', value: selectedPlacement });
    }
    updateAdSet(updatedAdSet);
  };

  const bundleKey = 'bundles';
  const { data: bundlesData, isSuccess: bundlesIsSuccess } = useGetBundles(
    {
      organisation_id: organisationId,
      ad_type_id: ids.ad_type_id,
      calendar_period_id: ids.calendar_period_id,
      location_id: ids.location_id,
    },
    {
      query: {
        queryKey: [bundleKey, organisationId, ids.ad_type_id, ids.calendar_period_id, ids.location_id],
        enabled: !!(ids.ad_type_id && ids.calendar_period_id && ids.location_id),
      },
    }
  );
  const columns: Array<GridColDef> = [
    {
      field: 'schedule',
      headerName: 'Schedule',
      minWidth: 350,
      type: 'custom',
      editable: true,
      renderEditCell: (params) => (
        <ScheduleCell apiRef={apiRef} organisationId={organisationId} setIds={setIds} {...params} />
      ),
    },
    {
      field: 'mediaType',
      headerName: 'Media type ',
      minWidth: 270,
      type: 'custom',
      editable: true,
      renderEditCell: (params) => (
        <MediaTypeCell apiRef={apiRef} organisationId={organisationId} setIds={setIds} {...params} />
      ),
    },
    {
      field: 'placement',
      headerName: 'Placement',
      minWidth: 250,
      type: 'custom',
      editable: true,
      renderEditCell: (params) => (
        <PlacementCell apiRef={apiRef} organisationId={organisationId} setIds={setIds} {...params} />
      ),
    },
    {
      field: 'skuCodes',
      headerName: 'SKU code(s)',
      minWidth: 100,
      type: 'custom',
      editable: true,
      renderEditCell: SkuCodesCell,
      renderCell: SkuCodesCell,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      minWidth: 130,
      editable: false,
      renderCell: (params) => <RateCell {...params} />,
    },
    {
      field: 'discount',
      headerName: 'Discount',
      minWidth: 70,
      editable: false,
      renderCell: (params) => <DiscountCell {...params} />,
    },
    {
      field: 'budget',
      headerName: 'Budget',
      minWidth: 130,
      editable: false,
      renderCell: (params) => <BudgetCell {...params} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'right',
      minWidth: 120,
      cellClassName: 'actions',
      align: 'right',
      type: 'actions',
      getActions: ({ id }: GridRowParams) => {
        return [
          <GridActionsCellItem
            key='edit'
            icon={<EditIcon />}
            label='Edit'
            aria-label='edit ad set'
            className='textPrimary'
            onClick={() => {
              setEditAdSet(adSets.find((adSet) => adSet.id === id) || undefined);
            }}
            color='inherit'
          />,
          <GridActionsCellItem
            key='copy'
            icon={<ContentCopyIcon />}
            label='Copy'
            aria-label='copy ad set'
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log('Copy ad set NOT IMPLEMENTED');
            }}
            color='default'
          />,
          <GridActionsCellItem
            key='delete'
            icon={<DeleteIcon />}
            label='Delete'
            aria-label='delete ad set'
            onClick={() => removeAdSet(id)}
            color='inherit'
          />,
        ];
      },
    },
  ];
  const title = viewMode === ViewMode.NEW ? 'Build your plan' : 'Add new ad sets';

  useEffect(() => {
    if (bundlesIsSuccess && focusedRowId) {
      // Create a new ad set array with the updated focused ad set
      const updatedAdSets = getAdSetsUpdatedWithNewBundle(bundlesData.data?.[0]?.id, focusedRowId, adSets);
      setIds({ ad_type_id: undefined, calendar_period_id: undefined, location_id: undefined });
      setAdSets(updatedAdSets);
    }
  }, [bundlesData, bundlesIsSuccess, ids, focusedRowId]);

  // Set edit mode for all editable cells (avoid user having to double click cell to enter edit mode)
  useEffect(() => {
    startCellEditModeForEditableColumns(adSets, columns, apiRef);
  }, [adSets, columns, apiRef]);

  return (
    <>
      <Box mb={1}>
        <Typography variant='h5' color='text.primary'>
          {title}
        </Typography>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        padding={2}
        sx={{ backgroundColor: 'white' }} //todo spacing
      >
        <DataGrid
          apiRef={apiRef}
          disableColumnFilter
          disableColumnSorting
          rows={adSets}
          columns={columns}
          pageSizeOptions={[5, 10, 15, 25, 50, 100]}
          rowHeight={60}
          editMode='row'
          showAddRowFooter={true}
          addRowFooterCallback={() => handleAddClick(setIds, addAdSet)}
          slotProps={{
            row: {
              onFocus: (event: React.FocusEvent<HTMLDivElement>) => {
                const newVal = event.currentTarget.getAttribute('data-id');
                setFocusedRowId(newVal);
              },
            },
          }}
          processRowUpdate={(newRow: AdSet) => updateAdSet(newRow)}
        />
        {editAdSet && (
          <AdSetModal
            adSet={editAdSet}
            isOpen={!!editAdSet}
            isReadOnly={false}
            onClose={() => {
              setEditAdSet(undefined);
            }}
            saveHandler={handleSaveAdSet}
          />
        )}
      </Box>
    </>
  );
};

export interface NewPlanAdSetsProps {
  organisationId: string;
  adSets: Array<AdSet>;
  addAdSet: (adSet: AdSet) => void;
  removeAdSet: (adSet: GridRowId) => void;
  updateAdSet: (adSet: AdSet) => void;
  setAdSets: (adSets: Array<AdSet>) => void;
  viewMode: ViewMode;
}
export interface Ids {
  ad_type_id: number | undefined;
  calendar_period_id: string | undefined;
  location_id: string | undefined;
}
